import React, { useState } from 'react';

const CommentMedia = ({ comment=null, previewUrl=null }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);

  // Toggle fullscreen modal
  const toggleFullscreen = () => {
    setIsFullscreen(!isFullscreen);
  };

  return (
    <div>
      {(previewUrl) && (
        <div
          className="rounded-xl h-20 w-20 overflow-hidden cursor-pointer"
          onClick={toggleFullscreen}
        >
          <img
            src={previewUrl}
            className="w-full h-full object-contain"
            alt="comment media"
          />
        </div>        
      )}
      {(comment?.media) && (
        <div
          className="rounded-xl h-20 w-20 overflow-hidden cursor-pointer"
          onClick={toggleFullscreen}
        >
          <img
            src={comment.media}
            className="w-full h-full object-contain"
            alt="comment media"
          />
        </div>
      )}

      {isFullscreen && comment && (
        <div className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50">
          <div className="relative w-full h-full flex items-center justify-center">
            <img
              src={comment.media}
              className="max-w-full max-h-full object-contain bg-black"
              alt="comment media fullscreen"
            />
            <button
              className="absolute top-4 right-4 text-white text-3xl font-bold"
              onClick={toggleFullscreen}
            >
              &times;
            </button>
          </div>
        </div>
      )}
      {isFullscreen && previewUrl && (
        <div className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50">
          <div className="relative w-full h-full flex items-center justify-center">
            <img
              src={previewUrl}
              className="max-w-full max-h-full object-contain bg-black"
              alt="comment media fullscreen"
            />
            <button
              className="absolute top-4 right-4 text-white text-3xl font-bold"
              onClick={toggleFullscreen}
            >
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CommentMedia;
