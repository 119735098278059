import { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import AuthContext from "./AuthProvider";
import { formatISODate, getAuthHeader, formatMatchDuration, isDuplicate, formatStat } from "../utils";

const Leaderboard = ({ location }) => {

  const {user} = useContext(AuthContext);
    const payload = {
    'location' : location,
  }
  const [leaderboard, setLeaderboard] = useState([]); 
  const [title, setTitle] = useState("")
  const [statName, setStatName] = useState("")
  const [userRanking, setUserRanking] = useState(null); 
  const [userStat, setUserStat] = useState(null); 
  const [userInfo, setUserInfo] = useState(null); 

  useEffect(() => {
    axios.post("/leaderboard/fetch_user_leaderboards/", payload, getAuthHeader())
    .then((response) => {
      setLeaderboard(response.data['leaderboard_stats']);
      setUserInfo(response.data['user']); 
      setTitle(response.data['title'])
      setStatName(response.data['stat_name'])
      if(response.data['user_ranking'] == null){
        setUserRanking("Unranked")
        setUserStat("No Stats")
      }else{
        setUserRanking(response.data['user_ranking'])
        setUserStat(response.data['user_stat']) 
      }
    });
  }, []);

  const renderLeaderboardContent = (leaderboard_title, stat_name) => {
      console.log("user", user); 
      return (
        <table className={"sm:h-20 w-full border-separate table-auto bg-black text-white rounded-3xl border-2 border-allTimeLeaderboardBorder"}>
            <thead>
              <tr class="h-2 pb-2 mb-9 sm:h-10 relative">
                <th colSpan="3" class={"w-full pl-4 pt-3 text-left text-lg font-bold pr-3 text-allTimeLeaderboardBorder"}>
                    {leaderboard_title}
                </th>
              </tr>

              <tr class="h-4 min-w-full relative">
                    <th class="h-2 w-1/3 pt-1 pb-3 pl-5 mb-9 sm:h-6 text-sm font-extralight text-left">rank</th>
                    <th class="h-2 w-1/3 pt-1 pb-3 pl-5 text-sm font-extralight text-left">player</th>
                    <th class="h-2 w-1/3 pt-1 pb-3 pr-3 mb-9 sm:h-6 text-sm font-extralight whitespace-nowrap text-right">{stat_name}</th> 
              </tr>
            </thead>
            <tbody class="h-2 w-4 px-12 mx-9 sm:h-10 sm:w-10 relative">
            {
                leaderboard.map((item, index) => {
                  const user_name = item['user']['username']
                  const profile_pic = item['user']['profile_pic']
                  const stat = item['stat']
                  const profileLink = `/profile/${user_name}`;
                  if(item['user']['id'] === user.user_id){
                    return  (
                      <tr class="h-4 w-6 sm:h-8 sm:w-8" key={index}>
                           <td className="h-2 w-1/3 pl-5 pr-6 text-cyan-400 sm:h-6 text-left pb-4">{index + 1}</td>
                           <td className="h-2 w-1/3 pl-3 sm:h-8 sm:w-6 text-left pb-4">
                               <Link className='flex items-center space-x-3' to={profileLink}>
                                   <div className="align-left h-8 w-8 relative items-end overflow-hidden rounded-full">
                                       <img src={profile_pic} alt="Medal" class="absolute top-0 left-0 w-full h-full object-cover" /> 
                                   </div>
                                   <span className="text-left text-cyan-400">{user_name}</span>
                               </Link>
                           </td>
                           <td className="h-6 w-1/3 pl-4 pr-3 pb-4 text-cyan-400 text-right">{formatStat(userStat)}</td>
                       </tr>); 
                  }else{
                    
                    return  (
                      <tr className="h-4 w-6 sm:h-8 sm:w-8" key={index}>
                           <td className="h-2 w-1/3 pl-5 pr-6 sm:h-6 text-left pb-4">{index + 1}</td>
                           <td className="h-2 w-1/3 pl-3 sm:h-6 text-left pb-4">
                               <Link className='flex items-center space-x-3' to={profileLink}>
                               <div className="align-left h-8 w-8 relative items-end overflow-hidden rounded-full">
                                    <img src={profile_pic} alt="Medal" className="absolute top-0 left-0 w-full h-full object-cover" /> 
                               </div>
                                   <span className="text-left text-black-900">{user_name}</span>
                               </Link>
   
                           </td>
                           <td className="h-6 w-1/3 pl-4 pr-3 pb-4 text-right">{formatStat(stat)}</td>
                       </tr>); 
                  }
                })
            }
            {
              (userRanking == "Unranked" || userRanking > 5) && 
                <tr className="h-4 w-6 sm:h-8 sm:w-8">
                <td className="h-2 w-1/3 pl-5 text-cyan-400 pr-6 sm:h-6 text-left pb-4">{userRanking}</td>
                <td className="h-2 w-1/3 pl-3 sm:h-6 text-left pb-4">
                    <Link className='flex items-center space-x-3' to={`/profile/${userInfo['username']}`}>
                        <div className="align-left h-8 w-8 relative items-end overflow-hidden rounded-full">
                            <img src={userInfo['profile_pic']} alt="Medal" className="absolute top-0 left-0 w-full h-full object-cover" /> 
                        </div>
                        <span className="text-left text-cyan-400">{userInfo['username']}</span>
                    </Link>

                </td>
                <td className="h-6 w-1/3 pl-4 pr-3 pb-4 text-cyan-400 text-right">{formatStat(userStat)}</td>
              </tr>
            }
            </tbody>
        </table>
    ); 
  }
  return renderLeaderboardContent(title, statName); 

}



export default Leaderboard;