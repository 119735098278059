import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import EditPostModal from "../components/EditPostModal";
import { getAuthHeader } from "../utils";

import MainLayout from "../layout/MainLayout";
import PostContent from "../components/PostContent";

import AuthContext from "../components/AuthProvider";


const Post = () => {
  // Dummy trigger
  const [trigger, setTrigger] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [currPost, setCurrPost] = useState({});
  const [userInfo, setUserInfo] = useState({});
  const [mentions, setMentions] = useState([]);
  const [commentTrigger, setCommentTrigger] = useState(false);
  const {user} = useContext(AuthContext);
  const [post, setPost] = useState({});
  const params = useParams();

  useEffect(() => {
    if (params.postID != null) {
      axios.get(`/posts/${params.postID}`, getAuthHeader())
      .then((response) => {
        setPost(response.data);
        setCommentTrigger(!commentTrigger);
        setLoaded(true);
      });
    }
    axios.get("/users", getAuthHeader())
    .then((response) => {
      setMentions(response.data.map((user, idx) => {
        return {
          "id": idx,
          "display": user?.username
        }
      }));
    })
  }, []);

  useEffect(() => {
    if (user != null) {
      axios.get(`/users/${user.user_id}/`, getAuthHeader())
      .then((response) => {
        setUserInfo(response.data);
      });
    }
  }, []);

  const handleOpenEditModal = (post) => (e) => {
    e.preventDefault();
    setCurrPost(post);
    setShowEdit(true);
  }

  return (
    <MainLayout>
      {(loaded) && <ol className="sm:max-w-[66%] max-w-full mx-auto items-center">
        <PostContent
          post={post}
          mentions={mentions}
          handleOpenEditModal={handleOpenEditModal}
          currUserInfo={userInfo}
          trigger={trigger}
          setTrigger={setTrigger}
          user={user}
        />
      </ol>}
      <EditPostModal
        show={showEdit}
        onHide={() => { setShowEdit(false) }}
        trigger={trigger}
        setTrigger={setTrigger}
        postID={currPost?.id}
        isFeat={(currPost?.post_type === "feat")}
      />
    </MainLayout>
  )
}


export default Post;