import { useState } from "react";
import axios from "axios";

import { getAuthHeader } from "../utils";

import { IconContext } from "react-icons/lib";
import { BsThreeDots } from "react-icons/bs";

const CommendToolTip = ({ commend, trigger, setTrigger }) => {
  const [showToolTip, setShowToolTip] = useState(false);

  const handleDeleteCommend = (commend) => {
    if (commend != null && commend?.id != null) {
      axios.patch("/users/commends/", {
        commend_id: commend?.id,
        delete_commend: true,
      }, getAuthHeader())
      .then((response) => {
        setTrigger(!trigger);
      })
    }
  }

  return (
    <div className="flex flex-row gap-2 items-start z-40">
      <button className="absolute top-0 right-0 z-40"
        onClick={(e) => {
          e.preventDefault();
          setShowToolTip(!showToolTip);
        }}
      >
        <IconContext.Provider value={{"color": "#969696"}}>
          <BsThreeDots size={20} />
        </IconContext.Provider>
      </button>
      {(showToolTip) && <div className="text-white text-lg top-0 right-6 absolute z-40">
        <ul className="border-[1px] border-[#D7FBFF] rounded-xl bg-gray-800 py-2 px-2 z-40">
          <li className="text-[#D9D9D9] text-[14px] text-poppins hover:underline hover:cursor-pointer z-40"
            onClick={() => {
              setShowToolTip(false);
              handleDeleteCommend(commend);
            }}
          >
            Delete
          </li>
        </ul>
      </div>}
    </div>
  )
}

export default CommendToolTip;