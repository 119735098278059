import { useState, useEffect } from "react";
import axios from "axios";

import { formatISODate, getAuthHeader, formatMatchDuration, isDuplicate } from "../utils";

const MatchesList = ({ game, matchInfo, setMatchInfo }) => {
  const [matches, setMatches] = useState([]);
  const [selectedMatches, setSelectedMatches] = useState([]);  

  useEffect(() => {
    axios.get("/dota_matches/get_dota_match_list", getAuthHeader())
    .then((response) => {
      setMatches(response.data);
      setSelectedMatches(new Array(response.data.length).fill(false));
    })
  }, []);

  const handleToggleAll = () => {
    const allSelected = selectedMatches.every(Boolean);
    const updatedSelectedMatches = matches.map(() => !allSelected);
    setSelectedMatches(updatedSelectedMatches);

    setMatchInfo(allSelected ? [] : matches);
  };

  const handleToggle = (index, match) => {
    const updatedSelectedMatches = [...selectedMatches];
    updatedSelectedMatches[index] = !updatedSelectedMatches[index];
    setSelectedMatches(updatedSelectedMatches);
  
    const updatedMatchInfo = updatedSelectedMatches[index]
        ? [...matchInfo, match]
        : matchInfo.filter(item => item.dota_match_id !== match.dota_match_id);
    setMatchInfo(updatedMatchInfo);
  };

  const renderTableBody = (game) => {
    switch (game?.title) {
      case "Dota 2": 
        return (
          <>
            {(matches).map((match, index) => {
              const wlColor = (match["win"] ? "text-[#00FF0A] px-2 sm:px-6 sm:py-1" : "text-[#E70000] px-2 sm:px-6 sm:py-1");
              const rowClass = match["match_has_insight_flag"] ? "border border-mint" : "bg-transparent"; // Apply light blue background if has_insight is true

              return (
                <tr className={`bg-transparent ${rowClass}`} onClick={() => handleToggle(index, match)}>
                  <td className="w-2 px-2 sm:px-4 sm:w-4 sm:py-1 bg-transparent">
                    <div className="flex items-center bg-transparent">
                      <input id="checkbox-table-search-1" type="checkbox"
                        className="w-2 h-2 sm:w-4 sm:h-4 text-black bg-white border-gray-300 rounded focus:ring-0"
                        checked={selectedMatches[index]}
                        onChange={(e) => {
                          e.stopPropagation();      // prevent the checkbox click from firing because the clicking anywhere in the row will already select the match
                          handleToggle(index, match);
                          }
                        }
                      />
                      <label for="checkbox-table-search-1" class="sr-only">checkbox</label>
                    </div>
                  </td>
                  <td className={wlColor}>
                    {match["win"] ? "Won" : "Lost"}
                  </td>
                  <td className="text-[12px] sm:text-sm px-2 py-[2px] sm:px-4 sm:py-1">
                    <img src={match["url_small_portrait"]} alt="" className="sm:h-7" />
                  </td>
                  <td className="text-[12px] sm:text-sm px-1 py-[2px] sm:px-4 sm:py-1">
                    {match["hero_name"]}
                  </td>
                  <td className="text-[12px] sm:text-sm px-2 py-[2px] sm:px-4 sm:py-1">
                    {formatISODate(match["match_start_time"])}
                  </td>
                  <td className="text-[12px] sm:text-sm px-2 py-[2px] sm:px-4 sm:py-1">
                    {match["game_mode_name"]}
                  </td>
                  <td className="text-[12px] sm:text-sm px-2 py-[2px] sm:px-4 sm:py-1">
                    {formatMatchDuration(match["duration"])}
                  </td>
                </tr>
              )
            })}
          </>
        );
      default:
        return null;
    }
  }

  const renderTableHead = (game) => {
    switch (game?.title) {
      case "Dota 2":
        return (
          <>
            <th scope="col" className="text-[9px] sm:text-[12px] px-2 py-[2px] sm:px-4 sm:py-1">
              RESULT
            </th>
            <th scope="col" className="text-[9px] sm:text-[12px] px-2 py-[2px] sm:px-4 sm:py-1">
              HERO
            </th>
            <th scope="col" className="text-[9px] sm:text-[12px] px-2 py-[2px] sm:px-4 sm:py-1">
              NAME
            </th>
            <th scope="col" className="text-[9px] sm:text-[12px] px-2 py-[2px] sm:px-4 sm:py-1">
              DATE
            </th>
            <th scope="col" className="text-[9px] sm:text-[12px] px-2 py-[2px] sm:px-4 sm:py-1">
              TYPE
            </th>
            <th scope="col" className="text-[9px] sm:text-[12px] px-2 py-[2px] sm:px-4 sm:py-1">
              DURATION
            </th>
          </>
        );
      default:
        return null;
    }
  }
  
  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg max-h-32 max-w-80 sm:max-w-full sm:max-h-48">
      <table class="w-full text-sm text-left rtl:text-right text-white font-poppins dark:text-gray-400 bg-transparent rounded-md">
        <thead class="text-xs text-white font-poppins bg-black uppercase dark:bg-gray-700 dark:text-gray-400 border-b-[1px] sticky top-0 z-50">
          <tr>
            <th scope="col" class="p-2 sm:p-4">
              <div class="flex items-center">
                <input id="checkbox-all-search" type="checkbox"
                  className="w-2 h-2 sm:w-4 sm:h-4 text-black bg-gray-100 border-gray-300 rounded focus:ring-0"
                  style={{
                    "accentColor": "#000"
                  }}
                  checked={selectedMatches.every(Boolean)}
                  onChange={handleToggleAll}
                />
                <label for="checkbox-all-search" class="sr-only">checkbox</label>
              </div>
            </th>
            {renderTableHead(game)}
          </tr>
        </thead>
        <tbody className="overflow-y-auto space-y-1 bg-black">
          {renderTableBody(game)}
        </tbody>
      </table>
    </div>
  );
}

export default MatchesList;