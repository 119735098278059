import Laugh from './reactions/1f923.png'
import LaughGif from './reactions/1f923.gif'
import Angry from './reactions/1f621.png'
import AngryGif from './reactions/1f621.gif'
import Heart from './reactions/2764.png'
import HeartGif from './reactions/2764.gif'
import WhoaGif from './reactions/1f92f.gif'
import Whoa from './reactions/1f92f.png' 

export const reactionPNG = {
    "laugh" : Laugh, 
    "heart" : Heart, 
    "angry" : Angry, 
    'whoa' : Whoa
}
export const reactionGIF = {
    "laugh" : LaughGif, 
    "heart" : HeartGif, 
    "angry" : AngryGif, 
    'whoa' : WhoaGif,

}

export const reactionsString = {
    "laugh" : "Haha", 
    "heart" : "GGWP", 
    "angry" : "Angry",
    "whoa" : "Whoa"
}