import React, { useState, useEffect } from "react";

import MediaCarousel from "./MediaCarousel";
import Comments from "./Comments";
import PostToolTip from "./PostToolTip";
import { TwitchClip } from "react-twitch-embed";
import { MdSwitchAccessShortcut } from "react-icons/md";
import axios from "axios";
import { getAuthHeader } from "../utils";
import {
  getMonthAndDay,
} from "../utils";


const formatPostText = (postBody) => {
  const mentionRegex = /@\[[a-zA-Z0-9_]+\]/g;
  const urlRegex = /https?:\/\/[^\s]+/g;

  if (!mentionRegex.test(postBody) && !urlRegex.test(postBody)) {
    return postBody;
  } else {
    const splitRegex = /(@\[[a-zA-Z0-9_]+\]|https?:\/\/[^\s]+)/g;
    const postBodyNodes = postBody.split(splitRegex);

    return (
      <>
        {postBodyNodes.map((chunk, index) => {
          const username = chunk.match(/@\[(\w+)\]/);
          const url = chunk.match(urlRegex);

          if (username) {
            return (
              <React.Fragment key={index}>
                <span>
                  <a className="text-[#BA99FB] rounded-md py-[1px] px-[2px]"
                    href={`/profile/${username[1]}`}
                  >
                    @{username[1]}
                  </a>
                </span>
              </React.Fragment>
            );
          } else if (url) {
            return (
              <React.Fragment key={index}>
                <a
                  key={index}
                  href={url[0]}
                  className="text-[#0FEFFD] underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {url[0]}
                </a>
              </React.Fragment>
            );
          } else {
            return <React.Fragment key={index}>{chunk}</React.Fragment>;
          }
        })}
      </>
    );
  }
}


const renderHiloPartyPlayers = (post) => {
  const isMobile = (window.screen.width < 600);

  if (post?.party_players == null || post?.party_players?.length === 0)
    return null;

  if (isMobile) {
    post.party_players = post.party_players.slice(0, 4);
  }

  return (
    <span className="items-center flex flex-row gap-1 ml-auto p-2">
      <p className="text-white text-[11px] sm:text-[14px] mr-1">with</p>
      {(post?.party_players).map((playerInfo) => {
        return (
          <div className="h-8 w-8 sm:h-10 sm:w-10 rounded-full overflow-hidden border-[1px] hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              if (playerInfo?.username?.length > 0)
                window.location.href = `/profile/${playerInfo.username}`;
            }}
          >
            <img src={playerInfo?.profile_pic} alt="" className="w-full h-full object-cover" />
          </div>
        )
      })}
    </span>
  );
}


const renderInsight = (currMatch) => {
  let header;
  let body;
  let fieldName;
  let label;
  let smaller = false;
  let gray = false;
  let percentileRank = currMatch?.metric_percentile_rank;

  switch (currMatch?.insight?.type) {
    case "most_hero_damage_in_match_flag":
      header = "DAMAGE DEALER";
      body = `Dealt more damage than ${percentileRank}% of players the past week`;
      label = "HERO DAMAGE";
      fieldName = "hero_damage";
      smaller = true;
      break;
    case "most_tower_damage_in_match_flag":
      header = "DEMOLITIONIST";
      body = `Dealt more tower damage than ${percentileRank}% of players the past week`;
      label = "TOWER DAMAGE";
      fieldName = "tower_damage";
      smaller = true;
      break;
    case "most_hero_healing_in_match_flag":
      header = "THE DOCTOR";
      body = `Restored more HP than ${percentileRank}% of players the past week`;
      label = "HP HEALED";
      fieldName = "hero_healing";
      break;
    case "highest_gold_per_minute_in_match_flag":
      header = "MONEY BAGS";
      body = `Achieved a higher GPM than ${percentileRank}% of players this past week`;
      label = "GOLD PER MIN";
      fieldName = "gold_per_minute";
      break;
    case "highest_xp_per_minute_in_match_flag":
      header = "GETTING WISER";
      body = `Achieved a higher XPM than ${percentileRank}% of players this past week`;
      label = "XP PER MIN";
      fieldName = "xp_per_minute";
      break;
    case "most_kills_in_match_flag":
      header = "JUST A SAVAGE";
      body = `Had more kills than ${percentileRank}% of players this past week`;
      label = "KILLS";
      fieldName = "kills";
      break;
    case "most_assists_in_match_flag":
      header = "HELPING HAND";
      body = `Had more assists than ${percentileRank}% of players this past week`;
      label = "ASSISTS";
      fieldName = "assists";
      break;
    case "most_deaths_in_match_flag":
      header = "CANNOT STAY ALIVE";
      body = `Died more times than ${percentileRank}% of players this past week`;
      label = "DEATHS";
      fieldName = "deaths";
      break;
    case "no_deaths_in_match_flag":
      header = "UNTOUCHABLE";
      body = `Had zero deaths in this game!`;
      break;
    case "camps_stacked":
      header = "STACKER";
      body = `Stacked a total of ${currMatch?.insight?.camps_stacked} camps this game!`;
      break;
    case "courier_kills":
      header = "RETURN TO SENDER";
      body = `Killed a total of ${currMatch?.insight?.courier_kills} couriers this game!`;
      break;
    case "denies_per_min":
      header = "EXCLAMATION";
      body = `Had a total of ${currMatch?.insight?.denies_per_min} denies this game!`;
      break;
    case "wards_placed":
      header = "EYES EVERYWHERE";
      body = `Placed ${currMatch?.insight?.wards_placed} wards this game!`;
      break;
    case "wards_destroyed":
      header = "CUT THE LIGHTS";
      body = `Destroyed ${currMatch?.insight?.wards_destroyed} wards this game!`;
      break;
    case "last_hits":
      header = "LANE STOMPER";
      body = `Had ${currMatch?.insight?.last_hits} last hits by 10:00 this game!`;
      break;
    case "game_duration":
      header = "NAIL BITER";
      body = `Game went on for ${currMatch?.insight?.game_duration}`;
      gray = true;
      break;
    case "reach_l6":
      header = "HEAD START";
      body = `Reached Level 6 at ${currMatch?.insight?.reach_l6}`;
      break;
    case "gold_earned_kills":
      header = "GOLD FROM KILLS";
      body = `Earned ${currMatch?.insight?.gold_earned_kills} gold from kills this game`;
      gray = true;
      break;
    case "gold_earned_assists":
      header = "GOLD FROM ASSISTS";
      body = `Earned ${currMatch?.insight?.gold_earned_assists} gold from assists this game`;
      gray = true;
      break;
    case "gold_lost_deaths":
      header = "GOLD LOST";
      body = `Lost ${currMatch?.insight?.gold_lost_deaths} gold from deaths this game`;
      gray = true;
      break;
    case "num_times_hero_played":
      header = "HERO PLAYED";
      body = `Played ${currMatch?.insight?.hero_name} ${currMatch?.insight?.num_times_hero_played} ${(currMatch?.insight?.num_times_hero_played === 1) ? "time" : "times"} in the last month`;
      gray = true;
      break;
    case "num_games_played":
      header = "GAMES PLAYED";
      body = `Has played ${currMatch?.insight?.num_games_played} games this week`;
      gray = true;
      break;
    default:
      return null;
  }

  return (
    <div className={"flex flex-col bg-gradient-to-r p-2 rounded-lg shadow-lg w-full" + 
                    (gray ? " from-[#505050] to-[#001A31]" : " from-insightGradientStart via-insightGradientMiddle to-insightGradientEnd")}>
      <p className={"text-[12px] font-poppins font-bold"
                    + (gray ? " text-[#D7FBFF]" : " text-[#2B2B2B]")
                    + (smaller ? " text-[10px] sm:text-[13px]" : " text-[12px] sm:text-[15px]")}
        style={{"textShadow": "text-shadow: 1px 1px 2px rgba(0,0,0, 0.25)"}}
      >
        {header} {(currMatch?.insight[fieldName] ? ` | ${Number(currMatch?.insight[fieldName]).toLocaleString()} ${label}` : "")}
      </p>
      <div className={"text-[12px]" + (gray ? " text-[#D7FBFF]" : " text-[#2B2B2B]")}>
        {body}
      </div>
    </div>
  );
}


export const renderFeat = (post) => {
  let itemURLS;
  switch (post?.feats[0]?.feat_type) {
    case "item_purchase":
      return (
        <div className="flex flex-row">
          <div className="flex flex-col flex-1 gap-2">
            <div className="px-2 sm:px-4 py-1 text-[10px] sm:text-sm font-bold text-[#2b2b2b] bg-gradient-to-r from-[#00ffea] to-[#9c5aff] rounded-lg text-center uppercase shadow-md self-start">
              BIG PURCHASE
            </div>
            <div className="flex flex-col gap-2">
              <div className="text-[12px] sm:text-[24px] font-poppins">
                <span className="font-bold">
                  {post.metadata?.item_purchase?.value?.username}
                </span>                
                {' bought a '}
                <span className="font-bold">
                  {`${post.metadata?.item_purchase?.value?.item_name}!`}
                </span>
              </div>
              {/* {post?.title && (
                <div className="text-[10px] sm:text-[16px] font-poppins mt-2 whitespace-pre-wrap">
                  {formatPostText(post.title)}
                </div>
              )} */}
            </div>
          </div>
          {/* <div className="h-20 overflow-hidden rounded-lg ml-auto hover:cursor-pointer"
            onClick={(e) => {
              e.preventDefault();
              window.open(post?.user_media[1]);
            }}
          >
            <img
              src={post?.user_media[0]}
              alt=""
              className="w-full h-full object-cover"
            />
          </div> */}
        </div>
      );
    case "rampage":
      itemURLS = post?.feats[0]?.context_games[0]?.items.map(item => item["img"]);
      return (
        <div className="flex flex-row gap-4">
          <div className="flex flex-col flex-1 gap-2">
            <div className="flex flex-row gap-1 sm:gap-3">
              <span className="px-2 sm:px-4 py-1 text-[10px] sm:text-sm font-bold text-[#2b2b2b] bg-gradient-to-r from-[#00ffea] to-[#9c5aff] rounded-lg text-center uppercase shadow-md self-start">
                RAMPAGE
              </span>
              <div className="flex flex-row">
                {(itemURLS).map((imgURL) => {
                  return (
                    <div className="h-6 w-6 sm:h-8 sm:w-8 overflow-hidden rounded-lg">
                      <img
                        src={imgURL}
                        alt=""
                        className="w-full h-full object-cover"
                      />
                    </div>
                  )
                })}
              </div>
            </div>
            <div className="text-[12px] sm:text-[24px] font-poppins">
              {`${post.author?.username} got a `}
              <span className="font-bold text-[#D1FFF4]">
                RAMPAGE
              </span>
              {` as ${post?.feats[0]?.context_games[0]?.hero_played?.name}!`}
            </div>
            {/* {post?.title && (
              <div className="text-[10px] sm:text-[16px] font-poppins mt-2 whitespace-pre-wrap">
                {formatPostText(post.title)}
              </div>
            )} */}
          </div>
          {/* <div className="w-10 h-10 sm:w-20 sm:h-20 overflow-hidden rounded-lg ml-auto"
            onClick={(e) => {
              e.preventDefault();
              window.open(post?.user_media[1]);
            }}
          >
            <img
              className="object-cover w-full h-full"
              src={post?.user_media[0]}
              alt=""
            />
          </div> */}
        </div>
      );
    case "no_deaths":
      itemURLS = post.metadata?.no_deaths?.value?.item_urls; 
      return (
        <div className="flex flex-row">
          <div className="flex flex-col flex-1 gap-2">
            <div className="flex flex-row gap-1 sm:gap-3">
                <span className="px-2 sm:px-4 py-1 text-[10px] sm:text-sm font-bold text-[#2b2b2b] bg-gradient-to-r from-[#00ffea] to-[#9c5aff] rounded-lg text-center uppercase shadow-md self-start">
                  UNTOUCHABLE
                </span>
                <div className="flex flex-row">
                  {(itemURLS).map((imgURL) => {
                    return (
                      <div className="h-6 w-6 sm:h-8 sm:w-8 overflow-hidden rounded-lg">
                        <img
                          src={imgURL}
                          alt=""
                          className="w-full h-full object-cover"
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="text-[12px] sm:text-[24px] font-poppins">
                {`${post.metadata?.no_deaths?.value?.username} had`}
                <span className="font-bold">
                  {' 0 deaths '}
                </span>
                {`as ${post.metadata?.no_deaths?.value?.hero_name}`}
              </div>
              {post?.title && (
                <div className="text-[10px] sm:text-[16px] font-poppins mt-2 whitespace-pre-wrap">
                  {formatPostText(post.title)}
                </div>
              )}
              {post?.body && (
                <div className="text-[10px] sm:text-[14px] font-poppins mt-2 whitespace-pre-wrap">
                  {formatPostText(post.body)}
                </div>
              )}
            </div>
          <div className="w-10 h-10 sm:w-20 sm:h-20 overflow-hidden rounded-lg ml-auto"
            onClick={(e) => {
              e.preventDefault();
              window.open(post?.user_media[1]);
            }}
          >
            <img
              className="object-cover w-full h-full"
              src={post?.user_media[0]}
              alt=""
            />
          </div>
        </div>
      );
    case "win_streak":
      return (
        <div className="flex flex-row gap-4">
          <div className="flex flex-col flex-1 gap-2">
            <span className="px-2 sm:px-4 py-1 text-[10px] sm:text-sm font-bold text-[#2b2b2b] bg-gradient-to-r from-[#00ffea] to-[#9c5aff] rounded-lg text-center uppercase shadow-md self-start">
              WIN STREAK
            </span>
            <div className="text-[12px] sm:text-[24px] font-poppins">
              <span className="font-bold">{post?.author?.username}{' '}</span>
              is on a
              <span className="text-[#00FF0A] font-bold">
                {` ${post?.feats[0]?.context_games?.length} `}
              </span>
              game winning streak!
            </div>
            {/* {post?.title && (
              <div className="text-[10px] sm:text-[16px] font-poppins mt-2 whitespace-pre-wrap">
                {formatPostText(post.title)}
              </div>
            )} */}
          </div>
        </div>
      );
    case "loss_streak":
      return (
        <div className="flex flex-row gap-4">
          <div className="flex flex-col flex-1 gap-2">
            <div className="px-2 sm:px-4 py-1 text-[10px] sm:text-sm font-bold text-[#2b2b2b] bg-gradient-to-r from-[#00ffea] to-[#9c5aff] rounded-lg text-center uppercase shadow-md self-start">
              LOSS STREAK
            </div>
            <div className="text-[12px] sm:text-[24px] font-poppins">
              <span className="font-bold">{post?.author?.username}{' '}</span>
              is on a
              <span className="text-[#E70000] font-bold">
                {` ${post?.feats[0]?.context_games?.length} `}
              </span>
              game losing streak!
            </div>
            {/* {post?.title && (
              <div className="text-[10px] sm:text-[16px] font-poppins mt-2 whitespace-pre-wrap">
                {formatPostText(post.title)}
              </div>
            )} */}
          </div>
        </div>
      );
    case "rank_change":
      let rank = post.feats[0]?.metadata?.rank.split(" ");  
      let rankNumber = "";
      let rankImage = ""
      if (rank.length === 2) {
        if (rank[0] != "Immortal"){
          rankNumber = `${window.location.origin}/${rank[1]}.png`;
        }
        rankImage = `${window.location.origin}/${rank[0]}.png`;
      } else if (rank.length === 1) {
        rankImage = `${window.location.origin}/${rank[0]}.png`;
      }
      return (
        <div className="flex flex-row gap-4">
          <div className="flex flex-col flex-1 gap-2">
            <div className="px-2 sm:px-4 py-1 text-[10px] sm:text-sm font-bold text-[#2b2b2b] bg-gradient-to-r from-[#00ffea] to-[#9c5aff] rounded-lg text-center uppercase shadow-md self-start">
              RANK CHANGE
            </div>
            <div className="text-[12px] sm:text-[24px] font-poppins">
              <span className="font-bold">{post?.author?.username}{' '}</span>
              is now a 
              <span className="text-[#D7FBFF] font-bold">
                {` ${post.feats[0]?.metadata?.rank} `}
              </span>
            </div>
            {/* {post?.title && (
              <div className="text-[12px] sm:text-[20px] font-poppins mt-2 whitespace-pre-wrap">
                {formatPostText(post.title)}
              </div>
            )}
            {post?.body && (
              <div className="text-[10px] sm:text-[14px] font-poppins mt-2 whitespace-pre-wrap">
                {formatPostText(post.body)}
              </div>
            )} */}
          </div>
          <div className="relative ml-2 w-20 h-20">
            <img src={rankImage} alt="Medal" className='absolute top-0 -left-2 w-20 h-20'/>
            {rankNumber && (
              <img src={rankNumber} alt="Stars" className='absolute top-0 -left-2 w-20 h-20'/>
            )}
          </div>
        </div>
      );
    default:
      return null;
  }
}

const renderNewWidgetGame = (post) => {
  const postType = (post?.metadata?.new_widget != null) ? "new_widget" : "new_games";

  switch (postType) {
    case "new_widget":
      return (
        <div className="flex flex-row mb-2 items-start">
          <div className="flex flex-col gap-1 sm:gap-3 align-top">
            <span className="px-2 sm:px-4 py-1 text-[10px] sm:text-sm font-bold text-white bg-gradient-to-r from-[#612ACD] via-[#A97FFC] to-[#667DFB] rounded-lg text-center uppercase shadow-md self-start">
              new profile widget
            </span>
          </div>
          <div className="flex flex-row gap-2 ml-auto">
            <div className="flex flex-col flex-1 gap-1">
              <span className="text-[14px] font-poppins font-light">
                {post.metadata?.new_widget?.name}
              </span>
              <span className="text-[20px] font-poppins font-bold">
                {post.metadata?.new_widget?.value?.body}
              </span>
            </div>
            {(post?.metadata?.new_widget?.value?.img) && 
            <div className="w-14 h-14 overflow-hidden rounded-lg ml-auto">
              <img
                src={post?.metadata?.new_widget?.value?.img}
                alt=""
                className="w-full h-full object-cover"
              />
            </div>}
          </div>
        </div>
      );
    case "new_games":
      return (
        <div className="flex flex-row">
          <div className="flex flex-col flex-1 gap-2">
            <div className="flex flex-col gap-1 sm:gap-3">
              <div className="flex flex-row gap-2 sm:gap-3">
                <span className="px-2 sm:px-4 py-1 text-[10px] sm:text-sm font-bold text-white bg-gradient-to-r from-[#612ACD] via-[#A97FFC] to-[#667DFB] rounded-lg text-center uppercase shadow-md self-start">
                  new game
                </span>
                {(post?.metadata?.new_games?.value?.game_imgs) && <div className="flex flex-row">
                  {(post?.metadata?.new_games?.value?.game_imgs).map((gameIcon) => {
                    return (
                      <div className="h-6 w-6 sm:h-10 sm:w-10 overflow-hidden rounded-lg">
                        <img
                          src={gameIcon}
                          alt=""
                          className="w-full h-full object-cover"
                        />
                      </div>
                    );
                  })}
                </div>}
              </div>
            </div>
            <div className="text-[14px] sm:text-[22px] font-poppins">
              {post.metadata?.new_games?.value?.game_titles}
            </div>
            {post?.title && (
              <div className="text-[10px] sm:text-[16px] font-poppins mt-2 whitespace-pre-wrap">
                {formatPostText(post.title)}
              </div>
            )}
          </div>
        </div>
      );
    default:
      return null;
  }
}


const PostBody = ({ post }) => {
  const [currMatch, setCurrMatch] = useState(post?.match_data[0]);
  const matchData = post?.match_data;
  const numWins = (post?.match_data.filter((match) => match.win)).length;
  const numLosses = (post?.match_data.filter((match) => !match.win)).length;
  const isGeneric = (post?.post_type === "generic");

  return (
    <div className="inline-flex flex-col gap-2 w-full mb-2">
      <p className="text-[22px] font-bold whitespace-pre-wrap text-white">{formatPostText(post.title)}</p>
      <p className="text-[14px] font-light whitespace-pre-wrap text-white">{formatPostText(post.body)}</p>
      <div className="flex sm:flex-row flex-col gap-4">
        {(isGeneric) && <div className="flex flex-row gap-12 mt-4 items-end">
          {(post.metadata?.metric_1) && <div className="flex flex-col gap-2">
            <div className="font-poppins font-light text-[16px] text-[#D8D8D8]">
              {post.metadata?.metric_1["name"]}
            </div>
            <div className="font-sans text-[18px] text-white">
              {post.metadata?.metric_1["value"]["custom"]}
            </div>
          </div>}
          {(post.metadata?.metric_2) && <div className="flex flex-col gap-2">
            <div className="font-poppins font-light text-[16px] text-[#D8D8D8]">
              {post.metadata?.metric_2["name"]}
            </div>
            <div className="font-sans text-[18px] text-white">
              {post.metadata?.metric_2["value"]["custom"]}
            </div>
          </div>}
          {(post.metadata?.metric_3) && <div className="flex flex-col gap-2">
            <div className="font-poppins font-light text-[16px] text-[#D8D8D8]">
              {post.metadata?.metric_3["name"]}
            </div>
            <div className="font-sans text-[18px] text-white">
              {post.metadata?.metric_3["value"]["custom"]}
            </div>
          </div>}
        </div>}
        {(!isGeneric) && <div className="flex flex-col gap-2 w-full">
          {/* Title and body text */}
          {(currMatch) && 
          <div className="flex flex-row gap-2">
            {/* Main hero picture */}
            <div className={"h-28 w-24 rounded-lg overflow-hidden border-l-8" + ((currMatch?.win) ? " border-l-[#00FF0A]" : " border-l-[#E70000]")}>
              <img src={currMatch?.hero_played?.vertical_portrait} alt="" className="w-full h-full object-cover" />
            </div>
            {/* Items + insights */}
            <div className="flex flex-col gap-2 w-full">
              {/* List of items used for that match */}
              <div className="flex flex-row gap-0.5 mr-auto">
                {(currMatch?.items).map((item) => {
                  return (
                    <div className="w-8 h-6 sm:w-9 sm:h-7 rounded-sm overflow-hidden">
                      <img src={item.img} alt="" className="w-full h-full object-cover" />
                    </div>
                  );
                })}
                <a className="w-7 h-7 sm:w-8 sm:h-8 rounded-sm overflow-hidden hover:cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    axios.get("/posts/stratz_click/", getAuthHeader())
                    const utmSource = "HiLo";
                    const redirectUrl = `https://stratz.com/matches/${currMatch?.dota_match_id}/?utm=${utmSource}`;
                    window.open(redirectUrl);
                  }}
                >
                  <img src="https://stratz.com/images/stratz_knowledge_graph_logo.png" alt="" className="w-full h-full object-cover" />
                </a>
              </div>
              {/* Insight for this match */}
              {renderInsight(currMatch)}
            </div>
          </div>}
          {(currMatch) && 
          <div className="flex flex-row justify-between">
            <div className="flex flex-col gap-1">
              <p className="font-poppins font-light text-[16px] text-[#D8D8D8]">
                Record
              </p>
              <div className="flex flex-row gap-2 font-sans text-[18px]">
                <span className="text-[#00FF0A]">
                  {numWins}W
                </span>
                -
                <span className="text-[#E70000]">
                  {numLosses}L
                </span>
              </div>
            </div>
            <div className="flex flex-col gap-1">
              <p className="font-poppins font-light text-[16px] text-[#D8D8D8]">
                KDA
              </p>
              <p className="font-sans text-[18px] text-white">
                {currMatch?.kills}/{currMatch?.deaths}/{currMatch?.assists}
              </p>
            </div>
            <div className="flex flex-col gap-0.5">
              <p className="font-poppins text-[16px] text-[#D8D8D8]">
                Avg Rank
              </p>
              <div className="h-12 w-12 rounded-sm overflow-hidden mx-auto">
                <img src={currMatch?.avg_match_rank} alt="" className="w-full h-full object-cover" />
              </div>
            </div>
            <div className="flex flex-col gap-0.5">
              <p className="font-poppins font-light text-[16px] text-[#D8D8D8]">
                Mode
              </p>
              <p className="font-poppins font-semibold text-[16px] text-white">
                {currMatch?.game_mode}
              </p>
            </div>
          </div>}
        </div>}
        {(matchData?.length > 0) && 
        <div className="sm:block inline-block whitespace-nowrap sm:gap-0 sm:justify-end max-h-44 overflow-x-auto sm:overflow-y-auto min-w-16 sm:min-w-20">
          {(matchData).map((match) => {
            return (
              <div className={"sm:block mr-1 sm:mx-0 inline-block min-w-16 w-16 sm:w-20 h-10 rounded-lg overflow-hidden hover:cursor-pointer border-l-4 mb-2" + ((match?.win) ? " border-l-[#00FF0A]" : " border-l-[#E70000]")}
                onClick={(e) => {
                  e.preventDefault();
                  setCurrMatch(match);
                }}
              >
                <img src={match?.hero_played?.full_portrait} alt="" className="w-full h-full object-cover" />
              </div>
            );
          })}
        </div>}
      </div>
    </div>
  )
}

const PostContent = ({
  post,
  mentions,
  handleOpenEditModal,
  currUserInfo,
  trigger,
  setTrigger,
  user
}) => {
  const [featVisible, setFeatVisible] = useState(true);
  const renderBorderGradient = (post?.post_type === "generic" || (post?.post_type === "session" && post?.is_edited));
  const isNewWidgetGame = (post?.post_type === "new_widget_game");
  const hasFeat = (post?.feats?.length > 0);
  const isMobile = (window.screen.width < 600);
  const youtubeEmbedURL = `https://www.youtube.com/embed/${post?.youtube_video_id}`;

  return (
    <li key={post?.id} id={`post-${post?.id}`} className="mx-3 my-3 py-0.5">
      <div className={(renderBorderGradient ? "w-full relative rounded-3xl p-[1px] bg-gradient-to-r from-[#00ffea] to-[#9c5aff] mt-2 sm:mt-4" + ((post?.post_type === "feat") ? "bg-opacity-0" : "") : "relative mt-2 sm:mt-4")}>
        {(hasFeat) &&
        <>
          <div className="absolute rounded-3xl bg-gradient-to-r from-[#00ffea] to-[#9c5aff] w-[98%] z-0 -top-2 left-3 backdrop-brightness-200">
            <p className="text-transparent">wow</p>
          </div>
          <div className="absolute rounded-3xl bg-gradient-to-b from-[#00ffea] to-[#9c5aff] h-[95%] z-0 -top-2 -right-2 rotate-180 backdrop-brightness-200">
            <p className="text-transparent">wow</p>
          </div>
        </>}
        <div className={"rounded-3xl p-4 sm:p-8 bg-[#0C0C0C] text-white pb-4 sm:pb-12 relative w-full" + (((featVisible && hasFeat) || isNewWidgetGame) ? " bg-feat bg-opacity-45 bg-cover" : "")}>
          {(hasFeat) && 
          <button
            className="absolute top-4 left-3"
            onClick={(e) => {
              e.preventDefault();
              setFeatVisible(!featVisible);
            }}
          >
            <MdSwitchAccessShortcut size={20} />  
          </button>}
          <div className="w-full inline-flex flex-col flex-1 relative">
            <div className="flex flex-row gap-2">
              {/* <div className="absolute h-6 w-6 -top-1 -left-1 overflow-hidden rounded-full">
                <img src={`${window.location.origin}/DuelWinner.png`} className="w-full h-full object-cover" />
              </div> */}
              <div className={"flex rounded-full h-10 w-10 sm:min-h-12 sm:min-w-12 sm:h-12 sm:w-12 overflow-hidden resize-none" + (hasFeat ? " mt-auto sm:mt-0" : "")}>
                <img src={post?.author?.profile_pic} alt="AnonymousAvatar" className="w-full h-full object-cover cursor-pointer" 
                onClick={() => {
                  window.location.href=`/profile/${post?.author.username}`;
                }}/>
              </div>
              <div className="flex flex-col ml-2">
                {(isMobile) ? <>
                  <span className="text-[14px]">
                    {post?.author?.username}
                  </span>
                  <span className="font-extralight text-[10px] sm:text-base">{getMonthAndDay(post?.date_created)}</span>
                </> : <span className="inline-block">
                  <span className="text-[14px] sm:text-base">{post?.author?.username}</span>
                  <span className="font-extralight text-[10px] sm:text-base ml-2 sm:ml-4">{getMonthAndDay(post?.date_created)}</span>
                </span>}
                {(post?.game) && <div className="flex flex-row gap-2 items-center">
                  <img src={post?.game?.icon_url} className="h-2 sm:h-4" />
                  <p className="text-[12px] sm:text-base">{post?.game?.title}</p>
                </div>}
              </div>
              {renderHiloPartyPlayers(post)}
            </div>
            <div className="mt-2 sm:mt-4">
              {(!hasFeat || !featVisible) && (!isNewWidgetGame) && <PostBody post={post} />}
              {(hasFeat && featVisible) && renderFeat(post)}
              {(isNewWidgetGame) && renderNewWidgetGame(post)}
            </div>
            {(post?.user_media?.length > 0 && post?.post_type !== "feat") && <div className="mt-2 sm:mt-4">
              {(post?.user_media?.length > 0 && post?.post_type !== "feat") && <MediaCarousel slides={post?.user_media} />}
            </div>}
            {(post?.twitch_clip_id?.length !== 0) ? <TwitchClip clip={post?.twitch_clip_id} className="w-auto h-[300px] block" autoplay muted/>
              : null}
            {(post?.youtube_video_id?.length > 0 && !isMobile) ? 
            <iframe height="300" src={youtubeEmbedURL} title="" frameborder="0" className="w-full"
            allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" />
            : null }
            {(post?.youtube_video_id?.length > 0 && isMobile) ? 
            <iframe height="200" src={youtubeEmbedURL} title="" frameborder="0" className="w-full"
            allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin" />
            : null }
            {(post != null) &&
            <Comments 
              postAuthor={post?.author?.username}
              postID={post?.id}
              currUserInfo={currUserInfo}
              mentions={mentions}
            />}
          </div>
          <div className="absolute top-4 right-2 sm:top-5 sm:right-4">
          {(post?.editable && user?.user_id === post?.author?.id) && (!isNewWidgetGame) && 
            <PostToolTip 
              key={`postToolTip-${post.id}`} 
              handleOpenEditModal={handleOpenEditModal}
              setTrigger={setTrigger}
              trigger={trigger}
              post={post}
            />}
          </div>
        </div>
      </div>
    </li>
  );
}

export default PostContent;