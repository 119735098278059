import React from "react";

const CommunityCard = ({ cardInfo }) => {
  const renderRank = (rawRank) => {
    if (rawRank == null)
      return null;

    let rank = rawRank?.split(" ");
    let frontendImageURLNumber = "";
    if (rank[0] != "Immortal") {
        frontendImageURLNumber = `${window.location.origin}/${rank[1]}.png`;
    }
    const frontendImageURL = `${window.location.origin}/${rank[0]}.png`;
    return (
      <div className="h-8 w-8 sm:h-16 sm:w-16 relative items-end overflow-hidden rounded-full">
        <img src={frontendImageURL} alt="Medal" className="absolute top-0 left-0 w-full h-full object-cover" />
        {frontendImageURLNumber && (
            <img src={frontendImageURLNumber} alt="Stars" className="absolute top-0 left-0 w-full h-full object-cover" />
        )}
      </div>
    );
  }

  const timeAgo = (date) => {
    const oldDate = new Date(date)
    const now = new Date();
    const seconds = Math.floor((now - oldDate) / 1000);
    let interval = seconds / 31536000;
    if (interval > 1) {
      return Math.floor(interval) + " years ago";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months ago";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days ago";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours ago";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes ago";
    }
    return Math.floor(seconds) + " seconds ago";
  }

  return (
    <div className="text-white rounded-3xl bg-black pt-3 pb-4 px-4 sm:pt-6 sm:px-6 flex flex-col gap-2">
      <div className="flex flex-row gap-4 items-center">
        <div className="h-10 w-10 sm:h-16 sm:w-16 rounded-full overflow-hidden">
          <img src={cardInfo?.profile_pic} alt="" className="w-full h-full object-cover" />
        </div>
        {renderRank(cardInfo?.rank)}
        <div className="h-6 w-6 sm:h-12 sm:w-12 rounded-full overflow-hidden">
          <img src={cardInfo?.hero} alt="" className="w-full h-full object-cover" />
        </div>
      </div>
      <div className="font-bold font-poppins text-[20px] overflow-x-clip">
        {cardInfo?.username}
      </div>
      <div className="font-poppins flex flex-col">
        <div className="text-[13px] text-[#C4C4C4]">last played</div>
        <div className="text-[13px] text-[#FFFFFF]">{timeAgo(cardInfo?.last_played)}</div>
      </div>
      <div className="font-poppins flex flex-col">
        <div className="text-[13px] text-[#C4C4C4]">position</div>
        <div className="text-[13px] text-[#FFFFFF]">{cardInfo?.position}</div>
      </div>
    </div>
  )
}

export default CommunityCard;