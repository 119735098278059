import React, { useContext, useState, useLocation } from 'react';
import axios from 'axios';

import Button from "../components/Button";

import PlaceholderPhoto from "../assets/add-photo.png";
import HiloLogo from "../assets/hilo-logo.png";
import GameSelector from '../components/GameSelector';
import AuthContext from '../components/AuthProvider';

import { 
  DropdownContainer,
  DropdownButton,
  DropdownMenu,
  DropdownItem,
} from "../components/Dropdown"
import { AiOutlineLoading } from "react-icons/ai";
import { IoIosArrowDown } from 'react-icons/io';
import { IconContext } from 'react-icons/lib';

import { getReadableTimezones, getTimezoneChoice } from '../timezones'; 
import AddGameSearch from '../components/AddGameSearch';

const Register = () => {

    const [registerInfo, setRegisterInfo] = useState({
      username: "",
      password: "",
      steamId: "",
    });
    const [newGames, setNewGames] = useState([]);
    const [selectedGames, setSelectedGames] = useState([]);
    const [next, setNext] = useState(true);
    const [games, setGames] = useState([]);
    const {loginUser} = useContext(AuthContext);
    const [selectedPic, setSelectedPic] = useState(null);
    const [previewUrl, setPreviewUrl] = useState(PlaceholderPhoto);
    const [showDrop, setShowDrop] = useState(false);
    const timezones = getReadableTimezones();
    const [selectedTimezone, setSelectedTimezone] = useState("");
    const [loading, setLoading] = useState(false);
    const [showImgErr, setShowImgErr] = useState(false);




    const isAlphanumeric = (str) => {
      const regex = /^[a-z0-9]+$/i;
      return regex.test(str);
    }

    const handleRegister = async (e) => {
      e.preventDefault();
      if (!isAlphanumeric(registerInfo["username"])) {
        alert(`Your username must only contain letters and numbers.`);
        return;
      }

      setLoading(true);
      // Map human-readable timezone for backend
      const userTimezone = getTimezoneChoice(selectedTimezone);

      const formData = new FormData();

      if (selectedPic != null)
        formData.append('file', selectedPic);

      formData.append('username', registerInfo["username"]);
      formData.append('password', registerInfo["password"]);
      formData.append('steam_id', registerInfo["steamId"]);
      formData.append('user_timezone', userTimezone);
      formData.append('games_played', JSON.stringify(newGames));

      axios.post("/users/register/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
      .then((response) => {
        // Hacky way to auto-login
        const synthE = {
          preventDefault: () => {},
          target: {
            username: {
              value: registerInfo["username"].toLowerCase()
            },
            password: {
              value: registerInfo["password"]
            }
          },
        }
        loginUser(synthE);
      });
    }

    const toggleDropdown = (e) => {
      e.preventDefault();
      setShowDrop(!showDrop);
    }

    const handleChangeTimezone = (timezone) => (e) => {
      e.preventDefault();
      setSelectedTimezone(timezone);
      setShowDrop(false);
    }

    const handleChange = (field) => (e) => {
      setRegisterInfo({...registerInfo, [field]: e.target.value});
    }

    const handleUploadChange = (e) => {
      const file = e.target.files[0];
      const maxFileSize = 10 * 1024 * 1024; // 10 MB in bytes

      if (!file || !file.type.startsWith('image/') || file.size > maxFileSize) {
        setShowImgErr(true);
        return;
      } else {
        setShowImgErr(false);
      }

      setSelectedPic(file);

      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      }
      if (file) {
        reader.readAsDataURL(file);
      }
    }

    return (
      <div className="h-screen w-full place-items-center">
        <div className="m-8">
          <img src={HiloLogo} className="h-14 sm:h-20" alt="HiLo" />
        </div>
        
        <div className="flex items-center justify-center">
          <div className="w-full max-w-5xl bg-transparent border-4 border-hiloLoginBorder rounded-login shadow mx-10 px-12 sm:px-32 sm:mb-20 py-12 sm:py-24 justify-center relative">
            {/* First screen, enter username and password */}
            {(next) && 
            <form className="sm:space-y-0 space-y-8 grid grid-cols-1 sm:flex sm:flex-row sm:gap-16 justify-items-center justify-center mb-12">
              <div className="sm:flex sm:flex-col sm:items-center sm:justify-center text-center" >
                <div className="h-24 w-24 sm:h-60 sm:w-60 mb-4 mx-auto sm:mb-12 rounded-full overflow-hidden">
                  <img src={previewUrl} alt="" className="w-full h-full object-cover" />
                </div>
                <label for="upload-pfp" className="max-w-40 text-center">
                  <p className="bg-gray-400 text-white py-2 px-12 rounded-full hover:cursor-pointer">
                    Upload
                  </p>
                </label>
                {(showImgErr) && 
                  <p className="mt-2 sm:mt-4 text-[10px] sm:text-[13px] text-red-500">Please upload a valid image that is under 10MB in size</p>
                }
                <input type="file" id="upload-pfp" accept="image/*" onChange={handleUploadChange} className="text-white hidden justify-self-center"/>
              </div>
              <div className="sm:flex sm:flex-col sm:gap-2 flex flex-col gap-4">
                <input type="username" name="username" id="username" placeholder="user name"
                  onChange={handleChange("username")}
                  value={registerInfo["username"]}
                  className="font-poppins sm:pl-6 bg-transparent bg-login-user border border-[#A1A1A1] text-white text-base font-light sm:text-[20px] rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 sm:pb-5 sm:mr-60" />
                <input type="password" name="password" id="password" placeholder="password"
                  onChange={handleChange("password")}
                  value={registerInfo["password"]}
                  className="font-poppins sm:pl-6 bg-transparent bg-login-user border border-[#A1A1A1] text-white text-base font-light sm:text-[20px] rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 sm:pb-5 sm:mr-60" required />
                <input type="text" name="steamID" id="steamID" placeholder="DOTA 2 Player ID"
                  onChange={handleChange("steamId")}
                  value={registerInfo["steamId"]}
                  className="font-poppins sm:pl-6 bg-transparent bg-login-user border border-[#A1A1A1] text-white text-base font-light sm:text-[20px] rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 sm:pb-5 sm:mr-60" required />
                <DropdownContainer className="bg-transparent border-gray-400 text-white text-base sm:text-lg rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full text-center sm:text-left sm:mt-auto">
                  <DropdownButton onClick={toggleDropdown}>
                    <span style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "3px"}}>
                      {(selectedTimezone === "") ? "Select your Timezone" : selectedTimezone}
                      <IoIosArrowDown />
                    </span>
                  </DropdownButton>
                  <div>
                    <DropdownMenu show={showDrop}>
                      {(timezones).map((timezone) => {
                        return (
                          <DropdownItem
                            onClick={handleChangeTimezone(timezone)}
                          >
                            {timezone}
                          </DropdownItem>
                        )
                      })}
                    </DropdownMenu>
                  </div>
                </DropdownContainer>
              </div>
            </form>}
            {(next) && 
            <Button color="clear" className="absolute right-8 bottom-8"
              onClick={(e) => {
                e.preventDefault();
                setNext(!next);
              }}
            >
              Next
            </Button>}

            {/* Second screen, choosing games */}
            {(!next) && 
              <GameSelector games={games} setGames={setGames}
                selectedGames={selectedGames} setSelectedGames={setSelectedGames}
                newGames={newGames} setNewGames={setNewGames}
              />
            }
            {(!next) && 
              <AddGameSearch newGames={newGames} setNewGames={setNewGames}/>
            }
            {(!next) && 
            <div className="absolute left-8 bottom-8">
              <button
                className="text-white py-2 sm:py-2 px-12 rounded-full sm:text-lg font-poppins border-2"
                onClick={(e) => {
                  e.preventDefault();
                  setNext(!next);
                }}
              >
                Previous
              </button>
            </div>
            }
            {(!next) &&
            <div className="absolute right-8 bottom-8 flex flex-row gap-16 items-start">
              <div>
                {(loading) && <span className="absolute animate-spin h-12 mr-3">
                  <IconContext.Provider
                    value={{ color: '#FFF'}}
                  >
                    <AiOutlineLoading size={48} />
                  </IconContext.Provider>
                </span>}
              </div>
              <button
                className="rgba(255, 255, 255, 0) bg-[#21818C] text-white py-2 px-12 rounded-full sm:text-lg font-poppins"
                onClick={handleRegister}
              >
                Confirm
              </button>
            </div>}
          </div>
        </div>
      </div>
    );
}

export default Register;
