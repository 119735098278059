import { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";

import EditPostModal from "./EditPostModal";
import Button from "./Button";
import '../styles/gradiantBox.css'
import { 
  getAuthHeader,
} from "../utils";

import InfiniteScroll from "react-infinite-scroll-component";
import PostContent from "./PostContent";

import AuthContext from "./AuthProvider";


const SessionList = ({ trigger, setTrigger, selectedGame, handleOpenPostModal,
  setSelectedGame, userInfo, hasMore, setHasMore, pageNum, setPageNum }) => {
  const [showEdit, setShowEdit] = useState(false);
  const [currPost, setCurrPost] = useState(null);
  const [currUserInfo, setCurrUserInfo] = useState({});
  const [mentions, setMentions] = useState([]);
  // Post pagination
  const [posts, setPosts] = useState([]);
  const {user} = useContext(AuthContext);
  const scrollTarget = (window.screen.width < 600 ? null : "sessionDiv");

  useEffect(() => {
    if (user != null) {
      axios.get(`/users/${user.user_id}`, getAuthHeader())
      .then((response) => {
        setCurrUserInfo(response.data);
      });
      axios.get("/users", getAuthHeader())
      .then((response) => {
        setMentions(response.data.map((user, idx) => {
          return {
            "id": idx,
            "display": user?.username
          }
        }));
      })
    }
  }, []);

  useEffect(() => {
    // Reset values in case they are stale
    setPosts([]);
    setPageNum(0);

    const url = (selectedGame != null) ? `/posts/?page=0&game=${selectedGame?.id}` : `/posts/?page=0`;
    axios.get(url, getAuthHeader())
    .then((response) => {
      let morePosts = [];
      for (let i = 0; i < response.data["posts"]?.length; i++) {
        let post = (
          <PostContent
            post={response.data["posts"][i]}
            mentions={mentions}
            handleOpenEditModal={handleOpenEditModal}
            currUserInfo={currUserInfo}
            trigger={trigger}
            setTrigger={setTrigger}
            user={user}
          />
        );
        morePosts.push(post);
      }

      if ("next" in response.data) {
        setPageNum(Number(response.data["next"]));
        setHasMore(true);
      } else {
        setHasMore(false);
      }
      setPosts(morePosts);
    });
  }, [trigger, selectedGame, user, currUserInfo]);

  const handleFetchPosts = () => {
    const url = (selectedGame != null) ? `/posts/?page=${pageNum}&game=${selectedGame?.id}` : `/posts/?page=${pageNum}`
    axios.get(url, getAuthHeader())
    .then((response) => {
      // Map raw posts to components
      let morePosts = [];
      for (let i = 0; i < response.data["posts"]?.length; i++) {
        let post = (
          <PostContent
            post={response.data["posts"][i]}
            mentions={mentions}
            handleOpenEditModal={handleOpenEditModal}
            currUserInfo={currUserInfo}
            trigger={trigger}
            setTrigger={setTrigger}
            user={user}
          />
        );
        morePosts.push(post);
      }

      // Extend posts array
      setPosts(posts.concat(morePosts));

      // Handle more posts case
      if (!("next" in response.data)) {
        setHasMore(false);
      } else {
        setPageNum(Number(response.data["next"]));
        setHasMore(true);
      }
    })
  }

  const handleOpenEditModal = (post) => (e) => {
    e.preventDefault();
    setCurrPost(post);
    setShowEdit(true);
  }

  const handleSaveDotaMatchesEndpoint = (e) => {
    e.preventDefault();
    axios.get("/dota_matches/save_dota_match", getAuthHeader())
  }


  return (
    <div id="sessionDiv" className="flex flex-col overflow-y-auto">
      <span className="flex flex-row items-end mb-2 sm:mb-4">
        <span className="text-xl sm:text-[24px] text-white inline-block">
          Sessions
          <span className="text-[#85B6FF] text-[10px] sm:text-sm ml-4 sm:ml-8 hover:cursor-pointer hover:underline"
            onClick={() => {
              setSelectedGame(null);
              setPageNum(0);
            }}
          >
            view all
          </span>
        </span>
        <div className="ml-auto">
          {(user?.user_id === userInfo?.id) && <Button color="gradient"
            size="sm"
            onClick={(e) => {
              handleSaveDotaMatchesEndpoint(e);
              handleOpenPostModal(e);
            }}
          >
            Create a Post
          </Button>}
        </div>
      </span>
      <InfiniteScroll
        dataLength={posts?.length}
        next={() => handleFetchPosts()}
        hasMore={hasMore}
        loader={<h4 className="text-[13px] sm:text-lg italic text-white">Loading...</h4>}
        className="pl-2 sm:pl-8"
        scrollThreshold="15px"
        scrollableTarget={scrollTarget}
      >
        <ol class="relative sm:mb-48">
          {posts.map((item) => item)}
        </ol>
      </InfiniteScroll>
      {(currPost) && 
      <EditPostModal
        show={showEdit}
        onHide={() => { setShowEdit(false) }}
        trigger={trigger}
        setTrigger={setTrigger}
        postID={currPost?.id}
        isFeat={(currPost?.post_type === "feat")}
        mentions={mentions}
      />}
    </div>
  )
}


export default SessionList;