import React, { useState } from 'react';
import styled from 'styled-components';

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownButton = styled.button`
  background-color: rgba(92, 225, 230, 0.2);
  color: #5CE1E6;
  padding: 5px 10px;
  font-size: 14px;
  border: 2px solid #5CE1E6;
  border-radius: 20px;
  cursor: pointer;
  font-family: 'Poppins';
`;

export const DropdownMenu = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  min-width: 160px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  border-radius: 4px;
  overflow: hidden;
  overflow-y: auto;
  max-height: 300px;
  margin: 10px;
`;

export const DropdownItem = styled.a`
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background-color: #000;

  &:hover {
    background-color: rgba(143, 255, 243, 0.6);
  }
`;

const Dropdown = () => {
  const [show, setShow] = useState(false);

  const toggleDropdown = () => setShow(!show);

  return (
    <DropdownContainer>
      <DropdownButton onClick={toggleDropdown}>Dropdown</DropdownButton>
      <DropdownMenu show={show}>
        <DropdownItem href="#action1">Action 1</DropdownItem>
        <DropdownItem href="#action2">Action 2</DropdownItem>
        <DropdownItem href="#action3">Action 3</DropdownItem>
      </DropdownMenu>
    </DropdownContainer>
  );
};

export default Dropdown;