import styled, { css } from 'styled-components';
import React from 'react';

const sizes = {
  sm: css`
    width: 300px;
  `,
  md: css`
    width: 500px;
  `,
  lg: css`
    width: 800px;
  `,
  xl: css`
    width: 1140px;
  `,
};

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1040;
`;

const ModalContainer = styled.div`
  background: linear-gradient(20deg, 
    rgba(35, 35, 35, 1) 0%, 
    rgba(38, 38, 38, 1) 26%, 
    rgba(0, 0, 0, 0.84) 71%, 
    rgba(10, 10, 10, 0.57) 100%
  );
  border-radius: 28px;
  padding: 20px;
  border: 2px solid #5CE1E6;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  ${(props) => sizes[props.size || 'md']}
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: 'Poppins';
  color: #5CE1E6;
  align-items: start;
  margin-bottom: 20px;
`;

const ModalTitle = styled.h2`
  margin: 0;
  width: 95%;
`;

const ModalCloseButton = styled.button`
  background: none;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  color: #FFFFFF;
`;

const ModalBody = styled.div`
  margin-bottom: 20px;
  color: #C4C4C4;
  font-family: 'Poppins';
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const HorizontalLine = styled.hr`
  border: none;
  height: 0.5px; /* Thickness of the line */
  background-color: #C4C4C4; /* Color of the line */
  margin: 8px 0; /* Space around the line */
`;

const Modal = ({ show, onHide, size, title, children, footer }) => {
  if (!show) return null;

  return (
    <Backdrop>
      <ModalContainer size={size} onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <ModalCloseButton onClick={onHide}>&times;</ModalCloseButton>
        </ModalHeader>
        <ModalBody>{children}</ModalBody>
        {footer && <HorizontalLine />}
        {footer && <ModalFooter>{footer}</ModalFooter>}
      </ModalContainer>
    </Backdrop>
  );
};

export default Modal;