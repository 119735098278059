import { createContext, useState, useEffect } from 'react'
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom'

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
    let [authTokens, setAuthTokens] = useState(() => {
      if (localStorage.getItem('authTokens') && localStorage.getItem('authTokens') != undefined && localStorage.getItem('authTokens') !== "undefined") {
        return JSON.parse(localStorage.getItem('authTokens'));
      }
      return null;
    });
    let [user, setUser] = useState(() => {
      if (localStorage.getItem('authTokens') && localStorage.getItem('authTokens') != undefined && localStorage.getItem('authTokens') !== "undefined") {
        return jwtDecode(localStorage.getItem('authTokens'));
      }
      return null;
    });
    let [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    let loginUser = async (e) => {
        e.preventDefault();
        let response = await fetch(`${process.env.REACT_APP_API_URL}/token`, {
            method: 'POST',
            headers:{
                'Content-Type': 'application/json'
            },
            body:JSON.stringify(
              {
                'username': (e.target.username.value).toLowerCase(),
                'password': e.target.password.value
              }
            )
        });
        let data = await response.json();

        if (response.status === 200) {
            // Delete existing tokens if set
            setAuthTokens(null);
            setUser(null);
            localStorage.removeItem('authTokens');
            // Set new ones
            setAuthTokens(data);
            const decoded = jwtDecode(data.access);
            setUser(decoded);
            localStorage.setItem('authTokens', JSON.stringify(data));
            navigate(`/profile/${(e.target.username.value).toLowerCase()}`);
        } else {
          if (e["setLoading"]) {
            e["setLoading"](false);
          }
        }
    };


    let handleSteamAuth = async (tokens, userData = null) => {
      try {
          // Clear any existing auth
          setAuthTokens(null);
          setUser(null);
          localStorage.removeItem('authTokens');

          // Set new tokens
          setAuthTokens(tokens);
          localStorage.setItem('authTokens', JSON.stringify(tokens));

          // Set user data
          const decoded = jwtDecode(tokens.access);
          console.log("decoded is ", decoded); 
          const finalUserData = userData || decoded;
          setUser(finalUserData);
          navigate('/'); 

          return true;
      } catch (error) {
          console.error('Error setting Steam auth:', error);
          return false;
      }
  };

    let logoutUser = () => {
        console.log("LOGGING OUT USER"); 
        setAuthTokens(null);
        setUser(null);
        localStorage.removeItem('authTokens');
        // navigate("/login");
    };


    let updateToken = async () => {
        let response = await fetch(`${process.env.REACT_APP_API_URL}/token/refresh`, {
            method: 'POST',
            headers:{
                'Content-Type':'application/json'
            },
            body:JSON.stringify({'refresh': authTokens?.refresh})
        });

        let data = await response.json();
        
        if (response.status === 200){
            setAuthTokens(data);
            setUser(jwtDecode(data.access));
            localStorage.setItem('authTokens', JSON.stringify(data));
        } else {
            logoutUser();
        }

        if (loading) {
            setLoading(false);
        }
    };

    let contextData = {
      user: user,
      authTokens: authTokens,
      setAuthTokens: setAuthTokens,  // Make sure these are included
      setUser: setUser,              // Make sure these are included
      loginUser: loginUser,
      logoutUser: logoutUser,
      handleSteamAuth: handleSteamAuth,  // Added new Steam auth handler
  };

    useEffect(()=> {
        if (loading) {
            updateToken();
        } 

        let fourMinutes = 1000 * 60 * 4;

        let interval = setInterval(() => {
            if (authTokens) {
                updateToken();
            }
        }, fourMinutes);
        return () => clearInterval(interval);
    }, [authTokens, loading]);

    return(
      <AuthContext.Provider value={contextData}>
        {children}
      </AuthContext.Provider>
    )
}