import React, { useEffect, useRef } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import AuthContext from './AuthProvider';
import { useContext } from 'react';

const SteamCallback = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const hasExecuted = useRef(false);
    const { handleSteamAuth } = useContext(AuthContext);

    useEffect(() => {
        const verifySteamLogin = async () => {
            if (hasExecuted.current) return;
            hasExecuted.current = true;
            console.log("EXECUTED"); 
            const queryParams = Object.fromEntries([...searchParams]);
            
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}/users/steam_verify/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(queryParams),
                });
                
                const data = await response.json();
                console.log(data); 
                if (response.ok) {
                    const success = await handleSteamAuth(
                        data.tokens,
                        data.status === 'new_user' ? data.user : null
                    );
                    
                    if (!success) {
                        console.error('Failed to set Steam auth');
                        navigate('/login?error=auth_failed');
                    }
                } else {
                    console.error('Steam verification failed:', data.detail);
                    navigate('/login?error=verification_failed');
                }
            } catch (error) {
                console.error('Error during Steam verification:', error);
                navigate('/login?error=verification_failed');
            }
        };
        
        verifySteamLogin();
        
    }, [searchParams, navigate, handleSteamAuth]);

    return (
        <div className="h-screen w-full flex items-center justify-center">
            <div className="text-white text-xl">
                Processing Steam login...
            </div>
        </div>
    );
};

export default SteamCallback;