import { useState } from "react";
import axios from "axios";

import { BsThreeDotsVertical } from "react-icons/bs";
import { getAuthHeader } from "../utils";

const PostToolTip = ({ handleOpenEditModal, post, setTrigger, trigger }) => {
  const [showToolTip, setShowToolTip] = useState(false);

  const handleDeletePost = (post) => {
    if (post != null && post?.id != null) {
      axios.delete(`/posts/${post?.id}`, getAuthHeader())
      .then((response) => {
        setTrigger(!trigger);
      })
    }
  }

  return (
    <>
      {(showToolTip) && <div className="fixed top-0 left-0 w-full h-full bg-transparent flex z-40"
        onClick={(e) => {
          e.preventDefault();
          setShowToolTip(false);
        }}
      />}
      <div className="flex flex-col gap-2 items-start">
        {(showToolTip) && <div className="text-white text-lg top-0 right-6 absolute min-w-20 z-50">
          <ul className="border-[1px] border-[#D7FBFF] rounded-xl bg-gray-800 py-2 px-2">
            <li className="text-[#D9D9D9] text-[14px] text-poppins hover:underline hover:cursor-pointer"
              onClick={(e) => {
                setShowToolTip(false);
                handleOpenEditModal(post)(e);
              }}
            >
              Edit Post
            </li>
            <li className="text-[#D9D9D9] text-[14px] text-poppins hover:underline hover:cursor-pointer"
              onClick={(e) => {
                setShowToolTip(false);
                handleDeletePost(post);
              }}
            >
              Delete
            </li>
          </ul>
        </div>}
        <button
          className="z-30"
          onClick={(e) => {
            e.preventDefault();
            setShowToolTip(!showToolTip);
          }}
        >
          <BsThreeDotsVertical size={24} />
        </button>
      </div>
    </>
  );
}

export default PostToolTip;