import React from 'react';
import { reactionGIF } from '../reactions'; // Assuming your reactionGIF object

const ReactionPopup = ({ onReactionClick }) => {
  return (
    <div className="absolute flex gap-3 bg-white border border-gray-300 p-2 rounded-lg shadow-md z-50 top-[-60px] left-0 min-w-max">
      {Object.keys(reactionGIF).map((reactionKey) => (
        <img
          key={reactionKey}
          src={reactionGIF[reactionKey]}  // Use the reactionGIF object to get the src
          alt={`${reactionKey} Reaction`}
          className="w-10 h-10 cursor-pointer transition-transform transform hover:scale-110"
          onClick={onReactionClick(reactionKey)}  // Call the curried function with the reaction key
        />
      ))}
    </div>
  );
};

export default ReactionPopup;