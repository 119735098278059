import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { DropdownContainer, DropdownButton, DropdownMenu, DropdownItem } from "../components/Dropdown";
import { 
  getAuthHeader,
  sameDay,
  getDayOfWeek,
} from "../utils";
import Button from "../components/Button";

import AuthContext from "../components/AuthProvider";
import PostModal from "../components/PostModal";
import InfiniteScroll from "react-infinite-scroll-component";
import MainLayout from "../layout/MainLayout";

import { IoIosArrowDown } from "react-icons/io";

import EditPostModal from "../components/EditPostModal";
import Leaderboard from "../components/Leaderboard";

import { HiOutlineQuestionMarkCircle } from "react-icons/hi2";
import VibesIcon from "../assets/vibes-icon.png";
import { IconContext } from "react-icons/lib";

import PostContent from "../components/PostContent";

const Feed = () => {
  // Post pagination
  const [posts, setPosts] = useState([]);
  const [games, setGames] = useState([]);
  const [pageNum, setPageNum] = useState(0);
  const [currPost, setCurrPost] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectedGame, setSelectedGame] = useState({
    "id": 0,
    "title": "View all"
  });
  const [showDrop, setShowDrop] = useState(false);
  const [trigger, setTrigger] = useState(false);
  const [showPostModal, setShowPostModal] = useState(false);
  const {user} = useContext(AuthContext);
  const [userInfo, setUserInfo] = useState({});
  const [vibes, setVibes] = useState([]);
  const [showVibeTip, setShowVibeTip] = useState(false);
  const [mentions, setMentions] = useState([]);
  const [feedType, setFeedType] = useState("everyone");
  const [isOn, setIsOn] = useState(false);
  const isMobile = (window.screen.width < 600);
  
  useEffect(() => {
    axios.get(`/games/`, getAuthHeader())
    .then((response) => {
      let allGames = [
        { "id": 0, "title": "View all"},
        ...response.data
      ]; // placeholder id = "0" value for 'view all'
      setGames(allGames);
      setPageNum(1);
    });
  }, []);

  useEffect(() => {
    if (user != null) {
      axios.get(`/users/${user.user_id}/`, getAuthHeader())
      .then((response) => {
        setPageNum(1);
        setUserInfo(response.data);
        axios.get("/users", getAuthHeader())
        .then((response) => {
          setMentions(response.data.map((user, idx) => {
            return {
              "id": idx,
              "display": user?.username
            }
          }));
        });
      });
      axios.get("/vibes/", getAuthHeader())
      .then((response) => {
        setVibes(response.data);
      });
    }
  }, []);

  const isGameNull = (game) => {
    return (game == null || (game?.id === 0))
  }

  useEffect(() => {
    // Reset values in case they are stale
    setPosts([]);
    setPageNum(1);

    let url = "";
    if (!isGameNull(selectedGame) && feedType === "everyone") {
      url = `/posts/feed/?page=1&game=${selectedGame?.id}&show=everyone`;
    } else if (!isGameNull(selectedGame) && feedType === "friends_only") {
      url = `/posts/feed?page=1&game=${selectedGame.id}&show=friends_only`;
    } else if (isGameNull(selectedGame) && feedType === "everyone") {
      url = `/posts/feed?page=1&show=everyone`;
    } else {
      url = `/posts/feed?page=1&show=friends_only`;
    }

    axios.get(url, getAuthHeader())
    .then((response) => {
      let morePosts = [];
      for (let i = 0; i < response.data["posts"]?.length; i++) {
        let post = (
          <PostContent
            post={response.data["posts"][i]}
            mentions={mentions}
            handleOpenEditModal={handleOpenEditModal}
            currUserInfo={userInfo}
            trigger={trigger}
            setTrigger={setTrigger}
            user={user}
          />
        );
        morePosts.push(post);
      }

      if ("next" in response.data) {
        setPageNum(Number(response.data["next"]));
        setHasMore(true);
      } else {
        setHasMore(false);
      }
      setPosts(morePosts);
    });
  }, [trigger, selectedGame, userInfo, mentions, feedType]);

  const toggleDropdown = () => setShowDrop(!showDrop);

  const handleSelectDropdown = (game) => (e) => {
    e.preventDefault();
    setPageNum(1);
    if (game?.id === 0)
      setSelectedGame({
        "id": 0,
        "title": "View all"
      });
    else
      setSelectedGame(game);
    setShowDrop(false);
  }

  const isEmptyUrl = (url) => {
    return (url === `""` || url?.length == 0);
  }

  const handleSaveDotaMatchesEndpoint = (e) => {
    e.preventDefault();
    axios.get("/dota_matches/save_dota_match", getAuthHeader())
  }

  const handleOpenEditModal = (post) => (e) => {
    e.preventDefault();
    setCurrPost(post);
    setShowEdit(true);
  }

  const handleFetchPosts = () => {
    let url = "";
    if (!isGameNull(selectedGame) && feedType === "everyone") {
      url = `/posts/feed/?page=${pageNum}&game=${selectedGame?.id}&show=everyone`;
    } else if (!isGameNull(selectedGame) && feedType === "friends_only") {
      url = `/posts/feed?page=${pageNum}&game=${selectedGame.id}&show=friends_only`;
    } else if (isGameNull(selectedGame) && feedType === "everyone") {
      url = `/posts/feed?page=${pageNum}&show=everyone`;
    } else {
      url = `/posts/feed?page=${pageNum}&show=friends_only`;
    }

    axios.get(url, getAuthHeader())
    .then((response) => {
      let morePosts = [];
      for (let i = 0; i < response.data["posts"]?.length; i++) {
        let post = (
          <PostContent
            post={response.data["posts"][i]}
            mentions={mentions}
            handleOpenEditModal={handleOpenEditModal}
            currUserInfo={userInfo}
            trigger={trigger}
            setTrigger={setTrigger}
            user={user}
          />
        );
        morePosts.push(post);
      }
      
      // Extend posts array
      setPosts(posts.concat(morePosts));
      // Handle more posts case
      if (!("next" in response.data)) {
        setHasMore(false);
      } else {
        setPageNum(Number(response.data["next"]));
        setHasMore(true);
      }
    })
  }

  const handleToggleVibeToolTip = (e) => {
    e.preventDefault();
    setShowVibeTip(!showVibeTip);
  }

  return (
    <MainLayout userInfo={userInfo}>     
      <div className="flex flex-row w-full align-middle justify-center">
        {(!isMobile) && <div className="flex flex-col flex-1 mr-4 my-8 min-w-[25%] max-w-[25%] items-center max-lg:hidden">
          <aside className="h-screen sticky top-0 overflow-y-scroll w-full"
            style={{
              "scrollbarWidth": "none"
            }}
          >
            {(showVibeTip) && 
            <div className="border-[#D7FBFF] border-[1px] rounded-3xl w-60 p-4 flex flex-col gap-4 bg-[#232323] top-12 right-0 absolute">
              <div className="text-[#D1FFF4] font-poppins text-[18px] font-bold">
                HiVibes highlights our amazing community members.
              </div>
              <div className="text-[#D9D9D9] text-[16px] font-poppins">
                Score points by being awesome in the community. Post cool stuff, support others,
                and join the conversation. The more you contribute, the higher your Vibes.
              </div>
              <div className="text-[#C9C9C9] text-[12px] font-poppins">
                Please note, HiLo has no tolerance for spam. Just don't do it :&#41;
              </div>
            </div>}
            <table className="sm:h-20 w-full border-separate table-auto items-center">
              <thead>
                <tr className="h-4 w-full items-center">
                  <th className="w-1/3 text-sm font-extralight text-left">
                    <div className="h-14 w-14 overflow-hidden">
                      <img src={VibesIcon} className="h-full w-full object-cover" />
                    </div>
                  </th>
                  <th className="h-2 w-1/3 text-white text-[18px] font-extralight text-left">
                    HiVibes
                  </th>
                  <th className="h-2 w-1/3 text-sm font-extralight whitespace-nowrap text-right">
                    <div className="flex justify-end hover:cursor-pointer"
                      onClick={handleToggleVibeToolTip}
                    >
                      <IconContext.Provider 
                        value={{"color": "#D7FBFF"}}
                      >
                        <HiOutlineQuestionMarkCircle size={24} />
                      </IconContext.Provider>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody className="h-2 w-4 mx-9 sm:h-10 sm:w-10 pt-8">
                {(vibes).map((vibe, idx) => {
                  return (
                    <tr className="h-4 w-6 sm:h-8 sm:w-8 text-white" key={idx}>
                      <td className="h-2 w-1/3 pl-6 pr-6 sm:h-6 text-left pb-4 font-bold">
                        {idx+1}
                      </td>
                      <td className="w-1/3 pr-6 text-left pb-4 hover:cursor-pointer"
                        onClick={(e) => {
                          e.preventDefault();
                          if (vibe?.username != null)
                            window.location.href =`/profile/${vibe?.username}`;
                        }}
                      >
                        {vibe?.username}
                      </td>
                      <td className="w-1/3 pl-4 pr-1 text-right pb-4 font-bold">
                        {vibe?.score}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </aside>
        </div>}
        <div className="flex flex-col flex-1 max-w-full sm:max-w-[45%] my-4 sm:my-8 max-sm:pr-3">
          <span className="flex flex-row items-end mb-2 w-full">
            <div className="flex flex-row items-end">
              <DropdownContainer>
                <DropdownButton onClick={toggleDropdown}>
                  <span className="flex flex-row items-center gap-2">
                    {selectedGame && !isEmptyUrl(selectedGame?.icon_url) && 
                      <img src={selectedGame?.icon_url} className="h-4" />
                    }
                    <div className="pr-1 sm:text-base text-[12px]">{selectedGame?.title}</div>
                    <IoIosArrowDown />
                  </span>
                </DropdownButton>
                <DropdownMenu show={showDrop}>
                  {(games).map((game) => {
                    return (
                      <DropdownItem onClick={handleSelectDropdown(game)}
                        className="hover:cursor-pointer"
                      >
                        {game.title}
                      </DropdownItem>
                    )
                  })}
                </DropdownMenu>
              </DropdownContainer>
              <span className="text-white flex flex-row gap-2 ml-2 items-center">
                <div className="font-poppins text-[12px] sm:text-[14px]">{(feedType === 'everyone') ? "Everyone" : "Friends only"}</div>
                <button
                  className={`relative inline-flex items-center rounded-full w-12 sm:w-16 h-7 transition-colors ${
                    isOn ? 'bg-[#D99BFF]' : 'bg-gray-200'
                  }`}
                  onClick={() => {
                    if (feedType === 'everyone') setFeedType('friends_only');
                    else setFeedType('everyone');
                    setIsOn(!isOn);
                  }}
                >
                  <span
                    className={`inline-block w-5 h-5 rounded-full transition-transform transform ${
                      isOn
                        ? 'translate-x-6 sm:translate-x-10 bg-[#262626]'
                        : 'translate-x-1 bg-gray-400'
                    }`}
                  ></span>
                </button>
              </span>
            </div>
            <div className="ml-auto">
              <Button color="gradient"
                size="sm"
                onClick={(e) => {
                  e.preventDefault();
                  handleSaveDotaMatchesEndpoint(e);
                  setShowPostModal(true);
                }}
              >
                Create a Post
              </Button>
            </div>
          </span>
          <InfiniteScroll
            dataLength={posts?.length}
            next={() => handleFetchPosts()}
            hasMore={hasMore}
            loader={<h4 className="text-[13px] sm:text-lg italic text-white">Loading...</h4>}
            className="pl-2 w-full"
            scrollThreshold={0.7}
          >
            <ol class="relative sm:mb-48">
              {posts.map((item) => item)}
            </ol>
          </InfiniteScroll>
          <PostModal
            show={showPostModal}
            onHide={() => setShowPostModal(false)}
            setTrigger={setTrigger}
            trigger={trigger}
            userInfo={userInfo}
            setPageNum={setPageNum}
          />
          {(currPost) && 
          <EditPostModal
            show={showEdit}
            onHide={() => { setShowEdit(false) }}
            trigger={trigger}
            setTrigger={setTrigger}
            postID={currPost?.id}
            isFeat={(currPost?.post_type === "feat")}
            mentions={mentions}
          />}
        </div>
        {(!isMobile) && <div className="flex flex-col flex-1 my-8 max-w-[33%] items-center max-lg:hidden">
        {
            <aside class="h-screen sticky top-0 overflow-y-scroll w-full pl-6"
              style={{
                "scrollbarWidth": "none"
              }}
            >
            <h1 className="font-poppins text-[24px] text-white my-3">
              Leaderboards
            </h1>
            <div className="my-4 w-full"> 
                <Leaderboard 
                location={"feed_1"}
                >
                </Leaderboard>
            </div>

            <div className="my-4 w-full"> 
                <Leaderboard 
                location={"feed_2"}
                >
                </Leaderboard>
            </div>
            <div className="my-4 w-full"> 
                <Leaderboard 
                location={"feed_3"}
                >
                </Leaderboard>
            </div>

          </aside>

        }
        </div>}
      </div>
    </MainLayout>
  );
}

export default Feed;