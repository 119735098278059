import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { CardTextarea } from "./PostCard";
import Modal from "./Modal";

import { IconContext } from 'react-icons/lib';
import { BsCameraVideo } from "react-icons/bs";
import { AiOutlinePicture } from "react-icons/ai";
import { CgSmileMouthOpen } from "react-icons/cg";
import { AiOutlineTag } from "react-icons/ai";
import { TbEyeFilled } from "react-icons/tb";
import { getAuthHeader, mapToEmoji } from "../utils";
import MediaCarousel from "./MediaCarousel";

import { Mention, MentionsInput } from "react-mentions";
import { emojis, emojiIDs } from "../emojis";

import "../styles/PostMentions.css"

const MentionsStyle = {
  backgroundColor: '#41487a',
  borderRadius: '5px',
}
const EmojisStyle = {
  backgroundColor: 'transparent',
}

const EditPostModal = ({ show, onHide, setTrigger, trigger, postID, isFeat, mentions }) => {
  const [postText, setPostText] = useState("");
  const [postTitle, setPostTitle] = useState("");
  const [initPostText, setInitPostText] = useState("");
  const [youtubeVideoLink, setYoutubeVideoLink] = useState("");

  const [youtubeInfo, setYoutubeInfo] = useState({});

  const [twitchInfo, setTwitchInfo] = useState({}); 
  const [twitchClipID, setTwitchClipID] = useState("") 

  // Photo uploading
  const [files, setFiles] = useState([]);
  const [previewUrls, setPreviewUrls] = useState([]);

  
  useEffect(() => {
    axios.get(`/posts/${postID}`, getAuthHeader())
    .then((response) => {
      if ("youtube_video_id" in response.data && response.data["youtube_video_id"]?.length > 0) {
        const youtubeURL = `/posts/youtube?video_id=${response.data["youtube_video_id"]}`;
        axios.get(youtubeURL, getAuthHeader())
        .then((youtubeResp) => {
          setYoutubeInfo(youtubeResp.data);
          setYoutubeVideoLink(response.data["youtube_video_id"]);
        }); 
      } else {
        setYoutubeInfo({});
        setYoutubeVideoLink("");
      }
      if("twitch_clip_id" in response.data && response.data['twitch_clip_id']?.length > 0){
        const twitchURL = `/posts/twitch?clip_id=${response.data["twitch_clip_id"]}`;
        axios.get(twitchURL, getAuthHeader())
        .then((twitchResponse) => {
          setTwitchInfo(twitchResponse.data); 
          setTwitchClipID(response.data["twitch_clip_id"]); 
        }); 
      }else{
        setTwitchInfo({});
        setTwitchClipID("");
      }
      setPostText(response.data.body);
      setInitPostText(response.data.body);
      setPostTitle(response.data.title);
    }); 
  }, [postID, show]);

  const handlePostText = (e) => {
    setPostText(e.target.value);
    /*const youtubeRegex = /(?:https:\/\/(?:www\.)?youtube\.com\/watch\?v=([^\s]+)|https:\/\/youtu\.be\/([^\s]+))/;
    const youtubeMatch = e.target.value.match(youtubeRegex);

    const twitchRegex = /https:\/\/(?:www\.)?twitch\.tv\/[^\/]+\/clip\/([^?\s]+)(\?[^\s]*)?|\bhttps:\/\/clips\.twitch\.tv\/([^?\s]+)(\?[^\s]*)?/;
    const twitchMatch = e.target.value.match(twitchRegex);
    if (youtubeMatch) {
      const videoId = youtubeMatch[1] || youtubeMatch[2];
  
      axios.get(`/posts/youtube?video_id=${videoId}`, getAuthHeader())
        .then((response) => {
          setYoutubeInfo(response.data);
          setYoutubeVideoLink(videoId);
          const cleanedText = e.target.value.replace(youtubeRegex, '').trim();
          setPostText(cleanedText);
        });
    } else if (twitchMatch) {
      const twitchClipID = twitchMatch[1] || twitchMatch[2]; 
      axios.get(`/posts/twitch?clip_id=${twitchClipID}`, getAuthHeader())
      .then((response) => {
        setTwitchInfo(response.data);
        setTwitchClipID(twitchClipID);
        const cleanedText = e.target.value.replace(twitchRegex, '').trim();
        setPostText(cleanedText);
      });
    }*/ 


  }

  const handlePostTitle = (e) => {
    setPostTitle(e.target.value);
    /*const youtubeRegex = /(?:https:\/\/(?:www\.)?youtube\.com\/watch\?v=([^\s]+)|https:\/\/youtu\.be\/([^\s]+))/;
    const youtubeMatch = e.target.value.match(youtubeRegex);

    const twitchRegex = /https:\/\/(?:www\.)?twitch\.tv\/[^\/]+\/clip\/([^?\s]+)(\?[^\s]*)?|\bhttps:\/\/clips\.twitch\.tv\/([^?\s]+)(\?[^\s]*)?/;
    const twitchMatch = e.target.value.match(twitchRegex);
    if (youtubeMatch) {
      const videoId = youtubeMatch[1] || youtubeMatch[2];
  
      axios.get(`/posts/youtube?video_id=${videoId}`, getAuthHeader())
        .then((response) => {
          setYoutubeInfo(response.data);
          setYoutubeVideoLink(videoId);
          const cleanedText = e.target.value.replace(youtubeRegex, '').trim();
          setPostTitle(cleanedText);
        });
    } else if (twitchMatch) {
      const twitchClipID = twitchMatch[1] || twitchMatch[2]; 
      axios.get(`/posts/twitch?clip_id=${twitchClipID}`, getAuthHeader())
      .then((response) => {
        setTwitchInfo(response.data);
        setTwitchClipID(twitchClipID);
        const cleanedText = e.target.value.replace(twitchRegex, '').trim();
        setPostTitle(cleanedText);
      });
    }*/ 

  }

  const handlePasteBody = (e) => {
    // Prevent the default paste behavior
    e.preventDefault();

    const clipboardData = e.clipboardData || window.clipboardData;
    const items = clipboardData.items;
  
    // Check if there's an image in the clipboard
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.includes('image')) {
        const file = item.getAsFile();
        const imageUrl = URL.createObjectURL(file);
  
        // Update files state with the pasted image
        setFiles((prevFiles) => [...prevFiles, file]);
  
        // Update previewUrls state to show the image preview
        setPreviewUrls((prevUrls) => [...prevUrls, imageUrl]);
  
        return; // Exit the function if we pasted an image
      }
    }
  
  
    const pastedText = e.clipboardData.getData('Text');
    const youtubeRegex = /(?:https:\/\/(?:www\.)?youtube\.com\/watch\?v=([^&\s]+)|https:\/\/youtu\.be\/([^?&\s]+))/;
    const youtubeMatch = pastedText.match(youtubeRegex);
  

    const twitchRegex = /https:\/\/(?:www\.)?twitch\.tv\/[^\/]+\/clip\/([^?]+)|https:\/\/clips\.twitch\.tv\/([^?]+)/;
    const twitchMatch = pastedText.match(twitchRegex);

    if (youtubeMatch) {
      const videoId = youtubeMatch[1] || youtubeMatch[2];
  
      axios.get(`/posts/youtube?video_id=${videoId}`, getAuthHeader())
        .then((response) => {
          setYoutubeInfo(response.data);
          setYoutubeVideoLink(videoId);
          const cleanedText = postText.replace(youtubeRegex, '').trim();
          setPostText(cleanedText);
        });
    } else if (twitchMatch) {
      const twitchClipID = twitchMatch[1] || twitchMatch[2]; 
      axios.get(`/posts/twitch?clip_id=${twitchClipID}`, getAuthHeader())
      .then((response) => {
        setTwitchInfo(response.data);
        setTwitchClipID(twitchClipID);
        const cleanedText = postText.replace(twitchRegex, '').trim();
        setPostText(cleanedText);
      });
    }
  }

  const handleEditPost = (e) => {
    e.preventDefault();

    let cleanedPostText = postText.replace(/\[(\S*?)\s(\p{Emoji_Presentation}|\p{Emoji}\uFE0F)\]/gu, '$2');
    cleanedPostText = cleanedPostText.replace(/\(\d+\)/g, "");

    let cleanedPostTitle = postTitle.replace(/\[(\S*?)\s(\p{Emoji_Presentation}|\p{Emoji}\uFE0F)\]/gu, '$2');
    cleanedPostTitle = cleanedPostTitle.replace(/\(\d+\)/g, "");

    const formData = new FormData();
    files.forEach((file, index) => {
      formData.append('user_media', file);
    });
    if(!isFeat){
      formData.append('title', cleanedPostTitle);
      formData.append('body', cleanedPostText);  
    }else{
      formData.append('title', cleanedPostTitle);
      formData.append('body', "") ;  
    }
    formData.append('youtube_video_id', youtubeVideoLink);
    formData.append('twitch_clip_id', twitchClipID);

    let baseHeader = getAuthHeader();
    baseHeader["headers"]["Content-Type"] = "multipart/form-data"

    axios.patch(`/posts/${postID}/`, formData, baseHeader)
    .then((response) => {
      setTrigger(!trigger);
      onHide();
    });

  }

  const handleUploadPhotos = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);

    // Generate preview URLs
    const urls = selectedFiles.map(file => URL.createObjectURL(file));
    setPreviewUrls(urls);
  }

  return (
    <Modal
      show={show}
      onHide={() => {
        setFiles([]);
        setPreviewUrls([]);
        onHide();
        setPostText(initPostText);
        setYoutubeInfo({});
        setYoutubeVideoLink("");
        setTwitchClipID(""); 
        setTwitchInfo({}); 
      }}
      size="lg"
      title={
      <span>
        {isFeat ? <MentionsInput
          value={postTitle}
          placeholder="Your caption here!"
          onChange={handlePostTitle}
          className="postTitleMentionWrapper"
          style={{
            "fontSize": "22px",
            "input": {
              overflow: "scroll",
              padding: "10px 11px",
              lineHeight: "32px"
            },
            "highlighter": {
              overflow: "hidden",
              lineHeight: "32px"
            }
          }}
          singleLine={false}
        >
          <Mention
            trigger="@"
            data={mentions}
            displayTransform={(id, display) => {
              if (!emojiIDs.includes(id)) {
                return `@${display}`;
              }
              return display;
            }}
            style={MentionsStyle}
            markup="@[__display__](__id__)"
          />
          <Mention
            trigger=":"
            data={emojis}
            displayTransform={(id, display) => {
              if (emojiIDs.includes(id)) {
                return mapToEmoji(display);
              }
              return display;
            }}
            style={EmojisStyle}
            appendSpaceOnAdd={true}
            markup="[__display__]"
          />
        </MentionsInput> : <MentionsInput
          value={postTitle}
          placeholder="Your caption here!"
          onChange={handlePostTitle}
          className="postTitleMentionWrapper"
          style={{
            "fontSize": "22px",
            "input": {
              overflow: "scroll",
              padding: "7px 11px",
              lineHeight: "32px"
            },
            "highlighter": {
              overflow: "hidden",
              lineHeight: "32px"
            }
          }}
          singleLine={false}
        >
          <Mention
            trigger="@"
            data={mentions}
            displayTransform={(id, display) => {
              if (!emojiIDs.includes(id)) {
                return `@${display}`;
              }
              return display;
            }}
            style={MentionsStyle}
            markup="@[__display__](__id__)"
          />
          <Mention
            trigger=":"
            data={emojis}
            displayTransform={(id, display) => {
              if (emojiIDs.includes(id)) {
                return mapToEmoji(display);
              }
              return display;
            }}
            style={EmojisStyle}
            appendSpaceOnAdd={true}
            markup="[__display__]"
          />
        </MentionsInput>}
      </span>
      }
      footer={
        <div className="grid grid-cols-2 w-full">
          <div className="flex gap-8 items-center col-span-1">
            <label htmlFor="upload-photos">
              <IconContext.Provider
                value={{ color: '#85B6FF' }}
              >
                <AiOutlinePicture size={24}/>
              </IconContext.Provider>
            </label>
            <input type="file" multiple name="pics" id="upload-photos" style={{ opacity: 0, position: "absolute", zIndex: "4" }}
              onChange={handleUploadPhotos}
            />
            <a
              style={{"cursor": "pointer"}}
            >
              <IconContext.Provider
                value={{ color: '#85B6FF' }}
                >
                <BsCameraVideo size={24} />
              </IconContext.Provider>
            </a>
            <a
              style={{"cursor": "pointer"}}
            >
              <IconContext.Provider
                value={{ color: '#85B6FF' }}
              >
                <CgSmileMouthOpen size={24} />
              </IconContext.Provider>
            </a>
            <a
              style={{"cursor": "pointer"}}
            >
              <IconContext.Provider
                value={{ color: '#85B6FF' }}
              >
                <AiOutlineTag size={24} />
              </IconContext.Provider>
            </a>
          </div>
          <div className="flex col-span-1 justify-end">
            <button
              onClick={handleEditPost}
              style={{
                borderRadius: "30px",
                background: "#85B6FF",
                color: "#FFFFFF",
                padding: "6px 16px",
                fontSize: "20px",
                border: "none",
                fontFamily: "Poppins",
                cursor: "pointer",
                zIndex: "5",
              }}
            >
              Post
            </button>
          </div>          
        </div>
      }
    >
      {(!isFeat) && 
        <div
        onPaste={handlePasteBody}
        style={{ position: 'relative', width: '100%' }}
      >
      <MentionsInput
        value={postText}
        placeholder="What's the latest?"
        onChange={handlePostText}
        className="postMentionWrapper"
        style={{
          "fontSize": "22px",
          "input": {
            overflow: "scroll",
            padding: "10px 11px",
            lineHeight: "32px"
          },
          "highlighter": {
            overflow: "hidden",
            lineHeight: "32px"
          }
        }}
        singleLine={false}
      >
        <Mention
          trigger="@"
          data={mentions}
          displayTransform={(id, display) => {
            if (!emojiIDs.includes(id)) {
              return `@${display}`;
            }
            return display;
          }}
          style={MentionsStyle}
          markup="@[__display__](__id__)"
        />
        <Mention
          trigger=":"
          data={emojis}
          displayTransform={(id, display) => {
            if (emojiIDs.includes(id)) {
              return mapToEmoji(display);
            }
            return display;
          }}
          style={EmojisStyle}
          appendSpaceOnAdd={true}
          markup="[__display__]"
        />
      </MentionsInput>
      </div>
    }

      {(previewUrls.length > 0) && <div className="py-4">
        {(previewUrls.length > 0) && 
        <MediaCarousel slides={previewUrls} />
        }
      </div>}
      {
      (youtubeVideoLink && youtubeVideoLink?.length > 0) && 
      <span
        className="flex flex-row sm:gap-4 sm:my-4 bg-black rounded-3xl sm:p-4 transition transform hover:-translate-y-2 hover:shadow-l hover:cursor-pointer"
      >
        <div>
          <img src={youtubeInfo["thumbnail_url"]} alt="" className="sm:h-12 sm:rounded-xl"/>
        </div>
        <div className="flex flex-col">
          <p className="font-sans sm:text-[16px] text-[#FFFFFF]">{youtubeInfo["video_title"]}</p>
          <p className="font-sans sm:text-[12px] text-white]">Channel: {youtubeInfo["channel_title"]}</p>
        </div>
        <img src="https://upload.wikimedia.org/wikipedia/commons/e/ef/Youtube_logo.png" alt="" className="ml-auto sm:h-12" />
      </span>
      }
      {(twitchClipID && twitchClipID?.length > 0) &&
        <span 
          className="flex flex-row sm:gap-4 sm:my-2 bg-black rounded-3xl sm:p-4 transition transform hover:-translate-y-2 hover:shadow-l hover:cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            window.open(twitchInfo["url"]);
          }}
        >
          <div>
            <img src={twitchInfo["thumbnail_url"]} alt="" className="sm:h-12 sm:rounded-xl"/>
          </div>
          <div className="flex flex-col">
            <p className="font-sans sm:text-[16px] text-[#9146FF]">{twitchInfo["title"]}</p>
            <p className="font-sans sm:text-[12px] text-white]">Streamer: {twitchInfo["broadcaster_name"]}</p>
          </div>
          <img src="https://cdn-icons-png.flaticon.com/512/5968/5968819.png" alt="" className="ml-auto sm:h-12" />
        </span>
      }

      <div style={{
        color: "#85B6FF",
        fontFamily: "Poppins",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "0.5rem"
      }}>
        <TbEyeFilled size={24} />
        <div>
          Everyone can see
        </div>
      </div>
    </Modal>
  )
}

export default EditPostModal;