import { useState } from "react";
import axios from "axios";
import { getAuthHeader, isDuplicate } from "../utils";

import { AiOutlineLoading } from "react-icons/ai";

const AddGameSearch = ({ newGames, setNewGames }) => {
  const [gameName, setGameName] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showResults, setShowResults] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleSubmitSearch = (e) => {
    e.preventDefault();
    setShowResults(true);
    setLoading(true);
    axios.post("/games/search/", {
      "title": gameName,
    }, getAuthHeader())
    .then((response) => {
      setSearchResults(response.data);
      setLoading(false);
    });
  }

  const handleChangeGame = (e) => {
    setGameName(e.target.value);
  }

  const transformResult = (result) => {
    // Transform to use 'icon_url' instead of 'artwork_url'
    result["icon_url"] = result["artwork_url"];
    delete result["artwork_url"];
    return result;
  }

  const handleResultClick = (result) => {
    // Add the new result only if it's not a duplicate
    result = transformResult(result);
    if (!isDuplicate(newGames, result)) {
      setNewGames([...newGames, result]);
    }
    setShowResults(false);
  }

  return (
    <form class="w-42 mx-auto sm:mb-8 sm:pb-0 pb-14">   
      <label for="default-search" class="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
      <div class="relative">
        <div class="absolute inset-y-0 start-0 flex items-center ps-2 sm:ps-3 pointer-events-none">
          <svg class="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
          </svg>
        </div>
        <div className="absolute inset-y-0 end-24 sm:end-32 flex items-center ps-3 pointer-events-none">
          {(loading) && <span className="absolute animate-spin h-6 mr-3">
            <AiOutlineLoading size={24} />
          </span>}
        </div>
        <input type="search" id="default-search" class="block w-full p-2 ps-8 sm:p-4 sm:ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Minecraft" required
          value={gameName}
          onChange={handleChangeGame}
        />
        <button className="text-white absolute end-2 sm:end-2.5 bottom-1 sm:bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-[12px] sm:text-sm px-2 sm:px-4 py-1.5 sm:py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          onClick={handleSubmitSearch}
        >
          Search
        </button>
        {(showResults) &&
          <div className="absolute z-10 w-full bg-white border border-gray-300 rounded-lg shadow-lg max-h-48 overflow-y-auto">
            {searchResults.map((result) => (
              <div
                key={result.igdb_id}
                className="p-2 cursor-pointer hover:bg-gray-100 flex flex-row gap-4 items-center"
                onClick={() => handleResultClick(result)}
              >
                <img src={result.artwork_url} className="h-8"/>
                <h1>
                  {result.title}
                </h1>
              </div>
            ))}
          </div>
        }
      </div>
      <div className="text-[12px] text-white font-poppins mt-4">
        This search may take a moment as it is searching through the entire library of 428,000+ games.
      </div>
    </form>
  )
}

export default AddGameSearch;