import React, { useState } from "react";

const MediaCarousel = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isMobile = window.screen.width < 600;

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  const openModal = (index) => {
    setCurrentIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="relative w-full max-w-xl mx-auto">
      <div className="overflow-hidden">
        <div
          className="flex transition-transform duration-500"
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {slides != null &&
            slides.length > 0 &&
            slides.map((slide, index) => {
              let imgStyle;
              if (!isMobile) {
                imgStyle = {
                  backgroundImage: `url(${slide})`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  height: "30vh", // Adjust the height as needed
                  backgroundColor: "black", // Ensure the black bars have a background color
                };
              } else {
                imgStyle = {
                  backgroundImage: `url(${slide})`,
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  height: "20vh", // Adjust the height as needed
                  backgroundColor: "black", // Ensure the black bars have a background color
                };
              }
              return (
                <div key={index} className="min-w-full" onClick={() => openModal(index)}>
                  <div style={imgStyle} className="w-full cursor-pointer" />
                </div>
              );
            })}
        </div>
      </div>
      {slides != null && slides.length > 1 && (
        <>
          <button
            onClick={goToPrevious}
            className="font-sans absolute top-1/2 left-0 transform -translate-y-1/2 text-white px-3 py-1 text-[24px]"
          >
            &#10094;
          </button>
          <button
            onClick={goToNext}
            className="font-sans absolute top-1/2 right-0 transform -translate-y-1/2 text-white px-3 py-1 text-[24px]"
          >
            &#10095;
          </button>
        </>
      )}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-100 flex justify-center items-center z-50">
          <div className="relative w-full h-full flex justify-center items-center" onClick={closeModal}>
            <img
              src={slides[currentIndex]}
              alt="Fullscreen"
              className="object-contain max-w-full max-h-full"
            />
            <button className="absolute top-14 right-2 sm:top-2 sm:right-2 text-white text-2xl z-50" onClick={closeModal}>
              &times;
            </button>
          </div>
          {slides.length > 1 && (
            <>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  goToPrevious();
                }}
                className="font-sans absolute left-0 top-1/2 transform -translate-y-1/2 text-white px-4 py-2 text-[24px]"
              >
                &#10094;
              </button>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  goToNext();
                }}
                className="font-sans absolute right-0 top-1/2 transform -translate-y-1/2 text-white px-4 py-2 text-[24px]"
              >
                &#10095;
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default MediaCarousel;
