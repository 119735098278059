import React, { useState } from 'react';
import ReactionPopup from './ReactionPopup';
import { reactionGIF, reactionPNG, reactionsString } from '../reactions';
import { FaRegHeart } from "react-icons/fa";
import axios from 'axios';
import { getAuthHeader } from '../utils';
import { getUniqueReactions } from '../utils';

export const ReactionButton = ({user, reacters, handleTogglePostLike, setReacters, setReactions, postID}) => {
  const [isHovered, setIsHovered] = useState(false);

  // Handle the mouse enter and leave events on the container
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);


  const handleReact = (reaction) => {
    return (e) => {
      e.preventDefault(); 
      if (user?.id != null && postID != null) {
        axios.patch(`/posts/${postID}/reactions/`, {
          user_id: user?.id,
          reaction_type : reaction, 
        }, getAuthHeader())
        .then((response) => { 
          if (response.data["reacted"]) {
            const new_data = reacters.map((item) => {
              if (item.user_id === user.id) {
                // If the current user is found, replace the reaction_type with the new one
                return {
                  ...item,
                  reaction_type: reaction,  // Update the reaction_type
                };
              }
              return item;  // Return unchanged items for other users
            });
          
            const userExists = reacters.some((item) => item.user_id === user.id);
            if (!userExists) {
              new_data.push({
                user_id: user.id,
                profile_pic: user.profile_pic,
                username: user.username,
                reaction_type: reaction,
              });
            }
          
            setReacters(new_data);  // Update the state with the modified array
            setReactions(getUniqueReactions(new_data)); 
          }          
          else {
            // Otherwise, remove self from liked
            const new_data = reacters.filter(liker => liker["username"] !== user?.username); 
            setReacters(new_data);
            setReactions(getUniqueReactions(new_data)); 
          }
          //console.log(reactions); 
          //console.log(response.data["reacted"])
        });
      }  
    }
  }


  const findReactionByUser = (reactionsArray)  => {
    const reaction = reactionsArray.find(
      (item) => item.user_id === user.id 
    );
    // If a matching username is found, return the reaction_type, else return null or any default value.
    return reaction ? reaction.reaction_type : null;
  }
  const reaction = findReactionByUser(reacters); 
  return (
    <div
      className="relative inline-block border-transparent border-4"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >

      {/* Main button */}
      <button
        className="flex flex-row gap-2 items-center"
        onClick={handleTogglePostLike}
      >
        {reaction
          ? <img src={reactionGIF[reaction]} alt="Liked" className="w-5 h-5" /> 
          : <FaRegHeart size={20} />}
        <p className="text-xs sm:text-sm">{reactionsString[reaction]}</p>
      </button>

      {/* Conditionally render the popup on hover */}
      {isHovered && <ReactionPopup onReactionClick={handleReact}/>}
    </div>
  );
};

export const CommentReactionButton = ({user, reacters, handleTogglePostLike, setReacters, setReactions, postID}) => {
  const [isHovered, setIsHovered] = useState(false);

  // Handle the mouse enter and leave events on the container
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);


  const handleReact = (reaction) => {
    return (e) => {
      e.preventDefault(); 
      if (user?.id != null && postID != null) {
        axios.patch(`/posts/${postID}/reactions/`, {
          user_id: user?.id,
          reaction_type : reaction, 
        }, getAuthHeader())
        .then((response) => { 
          if (response.data["reacted"]) {
            const new_data = reacters.map((item) => {
              if (item.user_id === user.id) {
                // If the current user is found, replace the reaction_type with the new one
                return {
                  ...item,
                  reaction_type: reaction,  // Update the reaction_type
                };
              }
              return item;  // Return unchanged items for other users
            });
          
            const userExists = reacters.some((item) => item.user_id === user.id);
            if (!userExists) {
              new_data.push({
                user_id: user.id,
                profile_pic: user.profile_pic,
                username: user.username,
                reaction_type: reaction,
              });
            }
          
            setReacters(new_data);  // Update the state with the modified array
            setReactions(getUniqueReactions(new_data)); 
          }          
          else {
            // Otherwise, remove self from liked
            const new_data = reacters.filter(liker => liker["username"] !== user?.username); 
            setReacters(new_data);
            setReactions(getUniqueReactions(new_data)); 
          }
          //console.log(reactions); 
          //console.log(response.data["reacted"])
        });
      }  
    }
  }


  const findReactionByUser = (reactionsArray)  => {
    const reaction = reactionsArray.find(
      (item) => item.user_id === user.id 
    );
    // If a matching username is found, return the reaction_type, else return null or any default value.
    return reaction ? reaction.reaction_type : null;
  }
  const reaction = findReactionByUser(reacters); 
  return (
    <div
      className="relative inline-block border-transparent border-4"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >

      {/* Main button */}
      <button
        className="flex flex-row gap-2 items-center"
        onClick={handleTogglePostLike}
      >
        {reaction
          ? <img src={reactionGIF[reaction]} alt="Liked" className="w-5 h-5" /> 
          : <FaRegHeart size={20} />}
        <p className="text-xs sm:text-sm">{reactionsString[reaction]}</p>
      </button>

      {/* Conditionally render the popup on hover */}
      {isHovered && <ReactionPopup onReactionClick={handleReact}/>}
    </div>
  );
};


