const LoginDecoBottom = () => {
  return (
    <div>
      <svg width="217" height="290" viewBox="0 0 217 290" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="-1.5" y="1.5" width="176" height="619" rx="12.5" transform="matrix(-1 0 0 1 214 0)" stroke="white" stroke-opacity="0.43" stroke-width="3" stroke-dasharray="14 14"/>
      <rect x="-1.5" y="1.5" width="176" height="619" rx="12.5" transform="matrix(-1 0 0 1 184 29)" stroke="white" stroke-opacity="0.43" stroke-width="3" stroke-dasharray="14 14"/>
      <rect x="-1.5" y="1.5" width="176" height="619" rx="12.5" transform="matrix(-1 0 0 1 155 62)" stroke="white" stroke-opacity="0.43" stroke-width="3" stroke-dasharray="14 14"/>
      <rect x="-1.5" y="1.5" width="176" height="619" rx="12.5" transform="matrix(-1 0 0 1 128 91)" stroke="white" stroke-opacity="0.43" stroke-width="3" stroke-dasharray="14 14"/>
      <rect x="-1.5" y="1.5" width="176" height="619" rx="12.5" transform="matrix(-1 0 0 1 98 120)" stroke="white" stroke-opacity="0.43" stroke-width="3" stroke-dasharray="14 14"/>
      <rect x="-1.5" y="1.5" width="176" height="619" rx="12.5" transform="matrix(-1 0 0 1 69 153)" stroke="white" stroke-opacity="0.43" stroke-width="3" stroke-dasharray="14 14"/>
      </svg>
    </div>
  )
}

const LoginDecoTop = () => {
  return (
    <div>
      <svg width="287" height="262" viewBox="0 0 287 262" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="1.5" y="-511.5" width="176" height="619" rx="12.5" stroke="white" stroke-opacity="0.43" stroke-width="3" stroke-dasharray="14 14"/>
      <rect x="31.5" y="-482.5" width="176" height="619" rx="12.5" stroke="white" stroke-opacity="0.43" stroke-width="3" stroke-dasharray="14 14"/>
      <rect x="60.5" y="-449.5" width="176" height="619" rx="12.5" stroke="white" stroke-opacity="0.43" stroke-width="3" stroke-dasharray="14 14"/>
      <rect x="87.5" y="-420.5" width="176" height="619" rx="12.5" stroke="white" stroke-opacity="0.43" stroke-width="3" stroke-dasharray="14 14"/>
      <rect x="117.5" y="-391.5" width="176" height="619" rx="12.5" stroke="white" stroke-opacity="0.43" stroke-width="3" stroke-dasharray="14 14"/>
      <rect x="146.5" y="-358.5" width="176" height="619" rx="12.5" stroke="white" stroke-opacity="0.43" stroke-width="3" stroke-dasharray="14 14"/>
      </svg>
    </div>
  )
}

export { LoginDecoTop, LoginDecoBottom };