import { useState, useEffect, useContext } from "react";
import axios from "axios";
import AuthContext from "../components/AuthProvider";
import GameSelector from '../components/GameSelector';
import AddGameSearch from '../components/AddGameSearch';

import HiloLogo from "../assets/hilo-logo.png";
import PlaceholderPhoto from "../assets/add-photo.png";
import { IoIosArrowDown } from 'react-icons/io';

import { 
  DropdownContainer,
  DropdownButton,
  DropdownMenu,
  DropdownItem,
} from "../components/Dropdown"
import { getReadableTimezones, getTimezoneChoice, getMainTimeZone } from "../timezones"; 
import { getAuthHeader } from "../utils";
import { IconContext } from "react-icons/lib";
import { AiOutlineLoading } from "react-icons/ai";

const Settings = () => {
  const {user} = useContext(AuthContext);
  const [next, setNext] = useState(false);

  const timezones = getReadableTimezones();
  const [updateInfo, setUpdateInfo] = useState({
    bio: "",
    playerId: "",
  });
  const [selectedPic, setSelectedPic] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(PlaceholderPhoto);
  const [showImgErr, setShowImgErr] = useState(false);
  const [showDrop, setShowDrop] = useState(false);
  const [selectedTimezone, setSelectedTimezone] = useState("");
  const [savingProfile, setSavingProfile] = useState(false);

  const [games, setGames] = useState([]);
  const [newGames, setNewGames] = useState([]);
  const [selectedGames, setSelectedGames] = useState([]);
  const [savingGames, setSavingGames] = useState(false);
  
  useEffect(() => {
    axios.get(`/users/${user.user_id}`, getAuthHeader())
    .then((response) => {
      setUpdateInfo({
        bio: response.data["bio"],
        playerId: response.data["steam_id"],
      });
      setSelectedTimezone(getMainTimeZone(response.data['user_timezone']))
      setPreviewUrl(response.data['profile_pic']); 
    });
  }, []);

  useEffect(() => {
    axios.get(`/games/?user_id=${user.user_id}`, getAuthHeader())
    .then((response) => {
      setSelectedGames(response.data.map(item => item.id));
      setNewGames(response.data);
    });
  }, []);

  const handleChange = (field) => (e) => {
    setUpdateInfo({...updateInfo, [field]: e.target.value});
  }

  const handleUploadChange = (e) => {
    const file = e.target.files[0];
    const maxFileSize = 10 * 1024 * 1024; // 10 MB in bytes

    if (!file || !file.type.startsWith('image/') || file.size > maxFileSize) {
      setShowImgErr(true);
      return;
    } else {
      setShowImgErr(false);
    }

    setSelectedPic(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewUrl(reader.result);
    }
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  const toggleDropdown = (e) => {
    e.preventDefault();
    setShowDrop(!showDrop);
  }

  const handleChangeTimezone = (timezone) => (e) => {
    e.preventDefault();
    setSelectedTimezone(timezone);
    setShowDrop(false);
  }

  const handleSaveProfile = (e) => {
    e.preventDefault();
    setSavingProfile(true);

    const formData = new FormData();

    if (selectedTimezone != null && selectedTimezone.length > 0) {
      const userTimezone = getTimezoneChoice(selectedTimezone);
      formData.append('user_timezone', userTimezone);
    }

    if (updateInfo["playerId"].length > 0)
      formData.append('steam_id', updateInfo["playerId"]);

    if (updateInfo["bio"].length > 0)
      formData.append('bio', updateInfo["bio"]);
  
    if (selectedPic != null && previewUrl !== PlaceholderPhoto)
      formData.append('file', selectedPic);
    
    if (newGames.length > 0)
      formData.append('games_played', JSON.stringify(newGames));

    let baseHeader = getAuthHeader();
    baseHeader["headers"]["Content-Type"] = "multipart/form-data";
    axios.patch(`/users/${user.user_id}/`, formData, baseHeader)
    .then((response) => {
      // stop saving icon
      setSavingProfile(false);
      window.location.href = `${window.location.origin}`;
    });
  }

  return (
    <div className="h-screen w-full place-items-center">
      <div className="m-8">
        <img src={HiloLogo} className="h-14 sm:h-20 hover:cursor-pointer" alt="HiLo"
          onClick={() => {
            window.location.href= `${window.location.origin}`;
          }}
        />
      </div>

      <div className="flex items-center justify-center">
        <div className="w-full max-w-5xl bg-transparent border-4 border-hiloLoginBorder rounded-login shadow mx-10 px-12 sm:px-32 sm:mb-20 py-12 sm:py-24 justify-center relative">
          {(!next) &&
          <form className="sm:space-y-0 space-y-8 grid grid-cols-1 sm:flex sm:flex-row sm:gap-16 justify-items-center justify-center mb-12">
            <div className="sm:flex sm:flex-col sm:items-center sm:justify-center text-center" >
              <div className="h-24 w-24 sm:h-60 sm:w-60 mb-4 mx-auto sm:mb-12 rounded-full overflow-hidden">
                <img src={previewUrl} alt="" className="w-full h-full object-cover" />
              </div>
              <label for="upload-pfp" className="max-w-40 text-center">
                <p className="bg-gray-400 text-white py-2 px-12 rounded-full hover:cursor-pointer">
                  Upload
                </p>
              </label>
              {(showImgErr) && 
                <p className="mt-2 sm:mt-4 text-[10px] sm:text-[13px] text-red-500">Please upload a valid image that is under 10MB in size</p>
              }
              <input type="file" id="upload-pfp" onChange={handleUploadChange} className="text-white hidden justify-self-center"/>
            </div>
            <div className="sm:flex sm:flex-col sm:gap-4 flex flex-col gap-4">
              <div>
                <p className="text-[16px] font-poppins text-white">
                  DOTA 2 Player ID
                </p>
                <input type="text" name="playerId" placeholder="DOTA 2 Player ID"
                  onChange={handleChange("playerId")}
                  value={updateInfo["playerId"]}
                  className="font-poppins sm:pl-6 bg-transparent bg-login-user border border-[#A1A1A1] text-white text-base font-light sm:text-[20px] rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 sm:pb-5 sm:mr-60" />
              </div>
              <div>
                <p className="text-[16px] font-poppins text-white">
                  Bio
                </p>
                <textarea name="bio" id="bio" placeholder="The best pokemon master that ever was"
                  onChange={handleChange("bio")}
                  value={updateInfo["bio"]}
                  className="resize-none font-poppins sm:pl-6 bg-transparent bg-login-user border border-[#A1A1A1] text-white text-base font-light sm:text-[20px] rounded-3xl focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 sm:pb-5 mr-60" />
              </div>
              <div>
                <p className="text-[16px] font-poppins text-white">
                  Timezone
                </p>
                <DropdownContainer className="bg-transparent border-gray-400 text-white text-base sm:text-lg rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full text-center sm:text-left">
                  <DropdownButton onClick={toggleDropdown}>
                    <span style={{display: "flex", flexDirection: "row", alignItems: "center", gap: "3px"}}>
                      {(selectedTimezone === "") ? "Select your Timezone" : selectedTimezone}
                      <IoIosArrowDown />
                    </span>
                  </DropdownButton>
                  <div>
                    <DropdownMenu show={showDrop}>
                      {(timezones).map((timezone) => {
                        return (
                          <DropdownItem
                            onClick={handleChangeTimezone(timezone)}
                          >
                            {timezone}
                          </DropdownItem>
                        )
                      })}
                    </DropdownMenu>
                  </div>
                </DropdownContainer>
              </div>
            </div>
          </form>}

          {(next) && 
            <GameSelector games={games} setGames={setGames}
              selectedGames={selectedGames} setSelectedGames={setSelectedGames}
              newGames={newGames} setNewGames={setNewGames}
            />
          }
          {(next) && 
            <AddGameSearch newGames={newGames} setNewGames={setNewGames}/>
          }
          {(!next) &&
          <div className="absolute left-8 bottom-8">
            <button
              className="text-white py-2 sm:py-2 px-4 sm:px-12 rounded-full text-sm sm:text-lg font-poppins border-2"
              onClick={(e) => {
                e.preventDefault();
                setNext(!next);
              }}
            >
              Select games
            </button>
          </div>}
          {(next) && 
          <div className="absolute left-8 bottom-8">
            <button
              className="text-white py-2 sm:py-2 px-4 sm:px-12 rounded-full text-sm sm:text-lg font-poppins border-2"
              onClick={(e) => {
                e.preventDefault();
                setNext(!next);
              }}
            >
             Previous 
            </button>
          </div>}
          {(next) && 
          <div className="absolute right-8 bottom-8 flex flex-row gap-16 items-start">
            <div>
              {(savingGames) && <span className="absolute animate-spin h-12 mr-3">
                <IconContext.Provider
                  value={{ color: "#FFF" }}
                >
                  <AiOutlineLoading size={48} />
                </IconContext.Provider>
              </span>}
              <button
                className="bg-[#21818C] text-white py-2 px-12 rounded-full sm:text-lg font-poppins"
                onClick={handleSaveProfile}
              >
                Confirm
              </button>
            </div>
          </div>}
          {(!next) &&
          <div className="absolute right-8 bottom-8 flex flex-row gap-16 items-start">
            <div>
              {(savingProfile) && <span className="absolute animate-spin h-12 mr-3">
                <IconContext.Provider
                  value={{ color: "#FFF" }}
                >
                  <AiOutlineLoading size={48} />
                </IconContext.Provider>
              </span>}
            </div>
            <button
              className="bg-[#21818C] text-white py-2 px-6 sm:px-12 rounded-full sm:text-lg font-poppins"
              onClick={handleSaveProfile}
            >
              Save
            </button>
          </div>}
        </div>
      </div>
    </div>
  );
}

export default Settings;