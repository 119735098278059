import React, { useState } from 'react';

const ReactionComponent = ({ reactions, reactionPNG, reactionData }) => {
  const [hoveredReaction, setHoveredReaction] = useState(null); // State to track the hovered reaction

  // Function to get the users with the hovered reaction
  const getUsersByReaction = (reaction) => {
    return reactionData.filter((dict) => dict.reaction_type === reaction);
  };

  return (
    <div className="flex items-center relative">
      {reactions.map((reaction, index) => (
        <div
          key={index}
          className="relative"
          onMouseEnter={() => setHoveredReaction(reaction)} // Set hovered reaction
          onMouseLeave={() => setHoveredReaction(null)} // Clear hovered reaction on leave
        >
          {/* Adding transparent border to increase the clickable area */}
          <div className="border-3 border-transparent">
            <img
              src={reactionPNG[reaction]}
              alt=""
              className={`cursor-pointer h-4 w-4 rounded-full z-[${40 - index * 10}]`} 
            />
          </div>

          {/* Popup for users list, only visible when hoveredReaction matches the current reaction */}
          {hoveredReaction === reaction && (
            <div className="absolute border-4 border-transparent bg-white rounded-lg z-50 min-w-max">
              <ul>
                {getUsersByReaction(reaction)
                  .slice(0, 10)
                  .map((user, idx) => (
                    <li
                      key={idx}
                      className="flex items-center space-x-2 whitespace-nowrap cursor-pointer hover:bg-gray-100 p-2 rounded-lg"
                    >
                      <img
                        src={user.profile_pic}
                        alt={user.username}
                        className="h-6 w-6 rounded-full object-cover"
                        onClick={() => {
                          window.location.href = `/profile/${user.username}`;
                        }}
                      />
                      <span
                        className="text-sm"
                        onClick={() => {
                          window.location.href = `/profile/${user.username}`;
                        }}
                      >
                        {user.username}
                      </span>
                    </li>
                  ))}
              </ul>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default ReactionComponent;